import React from 'react'
import {FC} from 'react'
import { Projects } from '../../core/_models'
type Props = {
    project: Projects
  }
const ProjectClientCell: FC<Props> = ({project}) => {
  return (
    <div>
      {project?.client_id}
    </div>
  )
}

export default ProjectClientCell
