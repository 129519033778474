import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
// import {UsersListFilter} from './UsersListFilter'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import * as XLSX from 'xlsx'
import { Link } from 'react-router-dom'

const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  const users = useQueryResponseData()
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "MyExcel.xlsx")
  }
  
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      

      {/* begin::Export */}
      <button   
              type='button' 
              className='btn btn-light-primary me-3'
              onClick={handleOnExport}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <Link to={'/Projects-Posted/Add-Project'}>
        <button type='button' className='btn btn-primary' >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Job
        </button>
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
