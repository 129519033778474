// import React, { useState, useEffect } from 'react'
import {/*CV,*/ User} from '../../core/_models'
import {FC} from 'react'
// import { isNotEmpty, Response } from '../../../../../../../_metronic/helpers'
// import axios, { AxiosResponse } from 'axios'
type Props = {
    user: User
}
    const API_URL = process.env.REACT_APP_THEME_API_URL
    const CV_URL = `${API_URL}/get-cv`


const UserJobCell: FC<Props> = ({user}) => {
    // const [response1, setResponse] = useState(Object)

//     useEffect(() => {
//         axios
//         .post(CV_URL, {
//             id:user.id
//         })
//         .then((response: AxiosResponse<Response<CV>>) => response.data)
//         .then((response: Response<CV>) => setResponse(response.data)) 
    
//         return () => {
//         <></>
//         }
//     }, [user.id]
// )
    
    


    // if (isNotEmpty(response1[0])){
        
    //     return(
    //         <ul>{response1[0]?.experience[0].designation}</ul>
    //     )
    //     }
        return(
            <>Position Not Given</>
        )
        }

export  {UserJobCell}