import {Column} from 'react-table'
import {ProjectInfoCell} from './ProjectInfoCell'

import {ProjectActionsCell} from './ProjectActionsCell'
import {ProjectSelectionCell} from './ProjectSelectionCell'
import {ProjectCustomHeader} from './ProjectCustomHeader'
import {ProjectSelectionHeader} from './ProjectSelectionHeader'
import {User} from '../../core/_models'
import ProjectBranchCell from './ProjectBranchCell'
import ProjectReqCandCell from './ProjectReqCandCell'
import ProjectSelCandCell from './ProjectSelCandCell'
import ProjectPenCandCell from './ProjectPenCandCell'
import ProjectDateCell from './ProjectDateCell'
import ProjectClientCell from './ProjectClientCell'

const ProjectColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <ProjectSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProjectSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Job No.' className='min-w-125px' />,
    id: 'client_id',
    Cell: ({...props}) => <ProjectClientCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Project/Sector' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <ProjectInfoCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Position Location' className='min-w-125px' />,
    id: 'location',
    Cell: ({...props}) => <ProjectBranchCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='I/O' className='min-w-125px' />,
    id: 'role',
    Cell: ({...props}) => <ProjectReqCandCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Salary' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({...props}) => <ProjectSelCandCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Assigned to' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ProjectPenCandCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'joinedas_day',
    Cell: ({...props}) => <ProjectDateCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProjectActionsCell id={props.data[props.row.index].id} />,
  },
]

export {ProjectColumns}
