import {useQueryClient, useMutation} from 'react-query'

import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {notifySelectedUsers} from '../../core/_requests'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react'
import { useState } from 'react'
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';

import { QUERIES } from '../../../../../../../_metronic/helpers';
const UsersListGrouping = () => {

  // const [DelNot, setDelNot] = useState(false)
  const {selected, clearSelected} = useListView()

  
  const [messageModalShow, setMessageModalShow] = useState(false);


  
  


  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      

      
      
      <button
        type='button'
        className='btn btn-info ms-2'
        onClick={() => setMessageModalShow(true)}
      >
        Message
      </button>
      {/* <button
        type='button'
        className='btn btn-danger'
        onClick={ handleJobOfferNotification}
      >
        Send to Client
      </button> */}
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <MessageModal
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
      />
      {/* <MailModal
        show={mailModalShow}
        onHide={() => setMailModalShow(false)}
      /> */}
    </div>
  )
}
function MessageModal(props:any) {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [message, setMessage] = useState('')
  const handleChange = (event:any) => {
    setMessage(event.target.value);
  };
  const notifySelectedItems = useMutation(() => notifySelectedUsers(selected, message), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
    
  })
const handleSubmit =async()=>{
    await notifySelectedItems.mutateAsync()
    swal({
      title: "Message sent successfully.",
      icon: "success",
      dangerMode: false,
    })
}
  // console.log("🚀 ~ file: UsersListGrouping.tsx:67 ~ MyVerticallyCenteredModal ~ message", message)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          MESSAGE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group 
              className="mb-3" 
              controlId="formBasicEmail">
        <Form.Label>Enter Message</Form.Label>
        <Form.Control 
                type="email" 
                placeholder="Enter message" 
                onChange={handleChange}
                as="textarea" aria-label="With textarea" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Button 
            variant="primary" 
            // type="submit"
            onClick={handleSubmit}
            >
            
        Submit
      </Button>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
// function MailModal(props:any) {
//   const {selected, clearSelected} = useListView()
//   const queryClient = useQueryClient()
//   const {query} = useQueryResponse()
//   const [message, setMessage] = useState('')
//   const handleChange = (event:any) => {
//     setMessage(event.target.value);
//   };
//   const notifySelectedItems = useMutation(() => mailSelectedUsers(selected, message), {
//     // 💡 response of the mutation is passed to onSuccess
//     onSuccess: () => {
//       // ✅ update detail view directly
//       queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
//       clearSelected()
//     },
    
//   })
// const handleSubmit =async()=>{
//     await notifySelectedItems.mutateAsync()
//     swal({
//       title: "Message sent successfully.",
//       icon: "success",
//       dangerMode: false,
//     })
// }
//   // console.log("🚀 ~ file: UsersListGrouping.tsx:67 ~ MyVerticallyCenteredModal ~ message", message)
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Email
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//       <Form>
//       <Form.Group 
//               className="mb-3" 
//               controlId="formBasicEmail">
//         <Form.Label>Enter Mail</Form.Label>
//         <Form.Control 
//                 type="email" 
//                 placeholder="Enter message" 
//                 onChange={handleChange}
//                 as="textarea" aria-label="With textarea" />
//         {/* <Form.Text className="text-muted">
//           We'll never share your email with anyone else.
//         </Form.Text> */}
//       </Form.Group>
//       <Button 
//             variant="primary" 
//             // type="submit"
//             onClick={handleSubmit}
//             >
            
//         Submit
//       </Button>
//     </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
export {UsersListGrouping}
