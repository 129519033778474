import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'

import { KTCard } from '../../../../../_metronic/helpers'
import { UserEditModal } from '../../worker-management/workers-list/user-edit-modal/UserEditModal'
import { AppliedWorkersTable } from './table/AppliedWorkersTable'




const AppliedWorkersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <AppliedWorkersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const AppliedWorkersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AppliedWorkersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default AppliedWorkersListWrapper
