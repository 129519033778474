import axios, { AxiosResponse } from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { KTCard, Response } from '../../../../../_metronic/helpers'
import { Client } from '../../clients-management/clients-list/core/_models'
import { addProject } from '../users-list/core/_requests'
import swal from 'sweetalert'
import { Navigate, useNavigate, useParams } from 'react-router'
import { User } from '../users-list/core/_models'
import { updateStatus } from '../../clients-management/clients-list/table/columns/Project-management/projects-list/core/_requests'
const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CLIENT_URL = `${API_URL}/get-clients`
const GET_CATEGORIES_URL = `${API_URL}/get-categories`
const GET_PROJECT_URL = `${API_URL}/get-projects`
const UPDATE_STATUS_URL = `${API_URL}/project-status`

const ApproveProjectPage:FC = () => {

    const routeParams = useParams()
    const client_id = routeParams?.client_id;
    const project_id = routeParams?.project_id;

    let navigate = useNavigate(); 
    
    const [clientName, setClientName] = useState<any>()
    const [client, setClient] = useState<any>()
    const [categories, setCategories] = useState<any>()
    const [value, setValue] = useState<any>()



const [isPosting, setIsPosting]=useState<boolean>(false)



const [category_id, setCategoryId] = useState<string | undefined>();
const [title, setTitle] = useState<string | undefined>();
const [description, setDescription] = useState<string | undefined>();
const [rate_hour, setRateHour] = useState<string | undefined>();
const [branch, setBranch] = useState<string | undefined>();
const [required, setRequired] = useState<string | undefined>();
const [selected, setSelected] = useState<string | undefined>();
const [required_at, setRequiredAt] = useState<string | undefined>();
const [responsibilities, setResponsibilities] = useState<string | undefined>();
const [working_hours, setWorkingHours] = useState<string | undefined>();
const [hiring_type, setHiringType] = useState<string | undefined>();
const [status, setStatus] = useState<string | undefined>();
const [project, setProject] = useState<any>();
let path = `/apps/worker-management/${project?.user_id}/workers`; 

useEffect(() => {
setCategoryId(project?.category_id);
setTitle(project?.title);
setDescription(project?.description);
setRateHour(project?.rate_hour);
setBranch(project?.branch);
setRequired(project?.required);
setSelected(project?.selected);
setRequiredAt(project?.required_at);
setResponsibilities(project?.responsibilities);
setWorkingHours(project?.working_hours);
setHiringType(project?.hiring_type);
setStatus(project?.status);
}, [project]);


    useEffect(() => {
    const fetchData = async () => {
        const [projectResponse, categoriesResponse ] = await Promise.all([
            axios.post(GET_PROJECT_URL, {
                id: project_id,
            }),
            axios.post(GET_CATEGORIES_URL),
            
        ]);
        setProject(projectResponse.data?.success)
        
        setCategories(categoriesResponse.data?.success);
    };
    fetchData();
    }, []);
useEffect(()=>{
    
        const fetchClient = async ()=>{
            const clientResponse = await axios.post(GET_CLIENT_URL, {
                id: project?.client?.user_id
            });
            setClient(clientResponse.data?.success);
        }
        fetchClient();
    

},[project]);   
if(isPosting){
    swal({
        title: "Are you sure?",
        text: "Once submitted, you will not be able to modify the project!",
        icon: "warning",
        buttons:["NO", "Yes"],
        dangerMode: true,
    })
    .then((willSubmit:any) => {
        if (willSubmit) {
            addProject(newproject);
            navigate(path);
            swal("Your project has been sent for approval!", {
            icon: "success",
            })
        }
        else {
        swal("Cancelled!", {
            icon: "error",
        });
        }
    });
    // axios
    // .post(UPDATE_STATUS_URL, {
    //     id:project?.id,
    //     status:"approved"
    // })
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    // .then((response: Response<User>) => response.data)

}
    
const today = new Date();
const dateToday =today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();



const newproject = {
            client_id:client_id ,
            category_id: category_id,
            title: title,
            description: description,
            rate_hour: rate_hour,
            branch: "Columbia",
            required: required,
            selected: 0,
            required_at: required_at,
            responsibilities: responsibilities,
            working_hours: working_hours,
            hiring_type: hiring_type,
            status: null,
            
}
    
const handleSubmit = ()=>{
    swal({
        title: "Are you sure?",
        text: "Once submitted, you will not be able to modify the project!",
        icon: "warning",
        buttons:["NO", "Yes"],
        dangerMode: true,
    })
    .then((willSubmit:any) => {
        if (willSubmit) {
            updateStatus(project?.id, "open");
            navigate(path);
            swal("Your project has been sent for approval!", {
            icon: "success",
            })
        }
        else {
        swal("Cancelled!", {
            icon: "error",
        });
        }
    });
}  
const handleClientInfo=(event:any)=>{
    if (event.target.value) {
    axios
    .post(GET_CLIENT_URL,{
        id:event.target.value
    })
    .then((response: AxiosResponse<Response<Client>>) => {
        const { data } = response;
        setClient(data?.success);
    })
    }
    
}
const joined_at = new Date(client?.created_at)? new Date(client?.created_at): new Date();
const hiringTyping = project?.hiring_type?project?.hiring_type:"Not given"
// console.log("🚀 ~ file: ApproveProjectPage.tsx:22 ~ client:", client)




return (
<>

    <form  className=''>
    <KTCard className='mb-3 p-5'>
        <h3 className='form-label fs-16 fw-bold'>01. General</h3>
            {/* First ROW */}
            <div className="row">

            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Client:</label>
                <input 
                    type="text" 
                    name="client_name" 
                    className="form-control form-control-solid" 
                    id="client_name" 
                    value = {client?.name}
                    readOnly />
            </div>
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Location:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="" 
                    onChange={(e)=>{}} 
                    defaultValue={client?.company_address} 
                    name="location"  
                    readOnly />
            </div>
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Date Recieved:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="" 
                    
                    value={dateToday} 
                    name="date_recieved" 
                    readOnly 
                    />
            </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <label className='form-label fs-6 fw-bold'>Job title:</label>
                    <input 
                        type="text" 
                        className="form-control form-control-solid"  
                        name="job_title" 
                        value={project?.title}
                        placeholder="Enter Job Title"  
                        readOnly />
                </div>
                <div className="col-md-4">
                    <label className='form-label fs-6 fw-bold'>Salary:</label>
                    <input 
                        type="number" 
                        className="form-control form-control-solid" 
                        name="salary"  
                        
                        value={project?.rate_hour}
                        readOnly />
                </div>
                <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Hiring Type:</label>
                <input 
                        type="number" 
                        className="form-control form-control-solid" 
                        name="hiringType"  
                        
                        value={hiringTyping}
                        readOnly />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>No. of candidates required:</label>
                <input 
                    type="number" 
                    className="form-control form-control-solid" 
                    placeholder="Enter Required Candidates"  
                    name="required_candidates" 
                    value={project?.required}
                    readOnly />
                </div>
                <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Required by(date):</label>
                <input 
                    type="date" 
                    className="form-control form-control-solid" 
                    placeholder="Enter Date"  
                    name="requiredDate"  
                    
                    readOnly/>
                </div>
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Category:</label>
                <input 
                    type="text" 
                    name="" 
                    className='form-control form-control-solid mb-3'
                    id="" 
                    readOnly />
                    
                </div>
            </div>
            
        </KTCard>
        <KTCard className='mb-3 p-5'>
            
            <h3 className='form-label fs-16 fw-bold'>02. Details</h3>
                  {/* First ROW */}
                <div className="row">
                    <div className="col-md-12"> 
                        <label className='form-label fs-6 fw-bold'>Job Description:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Description Not given'
                            name="job_description" 
                            id="job_description" 
                            value={project?.description}
                            cols={3} 
                            rows={10}
                            required
                            readOnly
                            >
                                
                        </textarea>
                    </div>
                    <div className="col-md-12"> 
                        <label className='form-label fs-6 fw-bold required'>Responsibilities:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Responsibilities not given'
                            name="responsibilitites" 
                            id="responsibilitites" 
                            value={project?.responsibilities}
                            cols={3} 
                            rows={10}
                            readOnly
                            required
                            >

                        </textarea>
                    </div>
                    <div className="col-md-12">
                    <label className='form-label fs-6 fw-bold required'>Working Hours:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Working Hours Not given'
                            name="working_hours" 
                            id="working_hours" 
                            value={[project?.working_hours]}
                            cols={3} 
                            rows={5}
                            readOnly
                            required
                            >
                        
                        </textarea>
                    </div>


                </div>
        
        </KTCard>
        <KTCard className='mb-3 p-5'>
        
            <h3 className='form-label fs-16 fw-bold'>03. Contact</h3>
            <div className="row">
            <div className="col-md-6">
                <label className='form-label fs-6 fw-bold'>Report to:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="Enter here" 
                    value={client?.contact_person}
                    name="report_to"  
                    readOnly/>
            </div>
            <div className="col-md-6">
                <label className='form-label fs-6 fw-bold'>Contact no:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="Enter contact no" 
                    value={client?.phone}
                    name="contact_no" 
                    readOnly />
            </div>
            <div className="col-md-6">
                <label className='form-label fs-6 fw-bold'>Company Address:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="Enter company address" 
                    name="company_address" 
                    value={client?.company_address}
                    readOnly />
            </div>
            <div className="col-md-6">
                <label className='form-label fs-6 fw-bold'>Joining Date:</label>
                <input 
                    type="text" 
                    className="form-control form-control-solid" 
                    placeholder="Enter {}" 
                    value={joined_at.toLocaleDateString()} 
                    name="joining_date"  
                    readOnly/>
            </div>
        
            </div>
        
        </KTCard>
        </form>
        <KTCard className='mb-3'>
        <fieldset >
        <form action=""className='p-5' >
            <h2>For Agency use</h2>
            <div className="row">
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Assigned to Branch:</label>
                <select
                        className='form-select form-select-solid fw-bolder'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-kt-user-table-filter='two-step'
                        data-hide-search='true'
                        // onChange={(e) => setLastLogin(e.target.value)}
                        // value={lastLogin}
                    >
                        <option value=''>Select</option>
                        <option value='1'>Branch 1</option>
                        <option value='2'>Branch 2</option>
                        <option value='3'>Branch 3</option>
                        <option value='4'>Branch 4</option>
                        <option value='5'>Branch 5</option>
                </select>
            </div>
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Assigned to Officer:</label>
                <input type="text" className="form-control form-control-solid" placeholder="Enter Officer name" name="officer_name"  />
            </div>
            <div className="col-md-4">
                <label className='form-label fs-6 fw-bold'>Job no:</label>
                <input type="text" className="form-control form-control-solid" value={project?.project_code} placeholder="Job No not set" name="job_no" readOnly />
            </div>
            </div>
            <div className=' text-right mt-5'>
            <button 
                className='btn btn-primary btn-block w-25 me-4 mt-2'
                type="button"
                onClick={handleSubmit}
                >
                Post in App
            </button>
            <button 
                className='btn btn-primary btn-block w-25  mt-2'
                type="button"
                onClick={handleSubmit}
            >
                Select Candidates and Post
            </button>
            
            </div>
        </form>
        </fieldset>
        </KTCard>
    
    </>
)
}

export default ApproveProjectPage