import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {UsersListFilter} from './UsersListFilter'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import * as XLSX from 'xlsx'
import { useState } from 'react'
import { useQueryRequest } from '../../core/QueryRequestProvider'
const UsersListToolbar = () => {
  const {updateState} = useQueryRequest()
  const users = useQueryResponseData()
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "MyClients.xlsx")
  }
  const [status, setStatus] = useState<number |undefined>(1);
  const handleStatus= (e:any)=>{
    const checkbox = e.currentTarget;
    checkbox.value = checkbox.checked ? '' : 1;
    setStatus(checkbox.value)
    updateState({
      filter: {
        
        availability: status, 
        
        
      },
      ...initialQueryState,
    })

}

  return (
    <div className='d-flex justify-content-end align-items-center 'onFocus={()=>{console.log("Focused")}} data-kt-user-table-toolbar='base'>
      
      <UsersListFilter />

      {/* begin::Export */}
      <button 
              type='button' 
              className='btn btn-light-primary me-3'
              onClick={handleOnExport}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>


      <form action="post">
      <label className="switch">
        <input 
            type="checkbox" 
            onClick={handleStatus}/>
        <div className="slider"></div>

      </label>
      <span className='text-primary'> Show Only Active</span>
      </form>
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
