import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const UsersListFilter = () => {
  
  
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  
  
  const [location, setLocation] = useState<string | undefined>()
  const [status, setStatus] = useState<string | undefined>()
  const [gender, setGender] = useState<string| undefined>()
  const [age, setAge] = useState<string | undefined>()
  

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({
      filter: undefined,
      ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {
        location: location,
        availability:status,
        gender:gender,
        age:age,
      },
      ...initialQueryState,
    })
  }

  const [blur, setBlur] = useState(false)
  return (
    <div>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-light-primary me-3`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onFocus={()=>{setBlur(true)
        console.log(blur)}}

        
        
        
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div 
          /*onBlur={()=>{
          setBlur(false)
          console.log(blur)}}*/
          className={blur?`menu menu-sub menu-sub-dropdown w-300px w-md-325px `:`menu menu-sub menu-sub-dropdown w-300px w-md-325px `} 
          data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          
          
          {/* begin::Input group */}
          <div className='mb-10 d-flex flex-column'>
            <label className='form-label fs-6 fw-bold'>Select Branch</label>
            {/* <select
              className='form-select form-select-solid fw-bolder'
            
              onChange={(e) => setLocation(e.target.value)}
              value={location}
            >
              <option value='Jersey'>Jersey</option>
              <option value='LA'>LA</option>
            </select> */}
            <label htmlFor="all" className='form-label fs-6 fw-normal'>
              <input  className="me-5"type="checkbox" name="branch" id="all" />All
            </label>
            <label htmlFor="LA" className='form-label fs-6 fw-normal'>
              <input  className="me-5"type="checkbox" name="branch" id="LA"defaultChecked={false} />LA
            </label>
            <label htmlFor="Jersey" className='form-label fs-6 fw-normal'>
              <input className="me-5" type="checkbox" name="branch" id="Jersey" />Jersey
            </label>
            <label htmlFor="branch3" className='form-label fs-6 fw-normal'>
              <input  className="me-5"type="checkbox" name="branch3" id="branch3" />branch3
            </label>
            <label htmlFor="branch4" className='form-label fs-6 fw-normal'>
              <input  className="me-5"type="checkbox" name="branch4" id="branch4" />branch4
            </label>
            <label htmlFor="branch5" className='form-label fs-6 fw-normal'>
              <input  className="me-5"type="checkbox" name="branch5" id="branch5" />branch5
            </label>

            
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status</label>
            <select
              className='form-select form-select-solid fw-bolder'
            
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value=''>All</option>
              <option value='1'>Active</option>
              <option value='0'>Inactive</option>
              <option value='2'>Occupied</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Gender</label>
            <select
              className='form-select form-select-solid fw-bolder'
            
              onChange={(e) => setGender(e.target.value)}
              value={gender}
            >
              <option value='both'>Both</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
              
            </select>
          </div>
          {/* end::Input group */}
          <div className="">
          <label className='form-label fs-6 fw-bold ms-2 my-auto'>Age: {age}</label><br />
          
          <input 
                type="range"
                min="18" 
                max="60"
                step="2"
                defaultValue={age}
                className="js-range-slider" 
                onChange={(e)=>{setAge(e.target.value)}} 
                name="my_range"
              />
					</div>
          {/* begin::Input */}
          <div className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-start my-2 '>
            <input
            id='saved_candidates'
              name='saved_candidates'
              className='form-check-input'
              type='checkbox'
              onClick={(e)=>{
                const checkbox = e.currentTarget;
                checkbox.value = checkbox.checked ? '0' : '1';
                setGender(checkbox.value)

            }}
              />
          <label htmlFor="saved_candidates" className='form-label fs-6 fw-bold ms-2 my-5'>Show Saved</label>
          </div>
          {/* end::Input group */}
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </div>
  )
}

export {UsersListFilter}
