import React from 'react'
import {FC} from 'react'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'

type Props = {
    project: Projects
  }
  const ProjectPenCandCell: FC<Props> = ({project}) => {
    const totalCandidates = project!.required;
    let selectedCandidates = project!.selected;
    let pendingCandidates;
    if(selectedCandidates=== null){
        selectedCandidates = 0;
    }
    if(selectedCandidates !== undefined && totalCandidates !== undefined){
        pendingCandidates = totalCandidates - selectedCandidates;
    }

  return (
    <div>
      {pendingCandidates}
    </div>
  )
}

export default ProjectPenCandCell
