import axios, {AxiosResponse} from 'axios'



import { ID, Response } from '../../../../../../../../../../_metronic/helpers'  
import {User, UsersQueryResponse} from './_models'


const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/get-client-projects`
const GET_USERS_URL = `${API_URL}/get-client-projects`
const REGISTER_USER_URL = `${API_URL}/register`
const DELETE_PROJECTS_URL = `${API_URL}/del-projects`
const UPDATE_STATUS_URL = `${API_URL}/project-status`
const getUsers = (query: string, id:string): Promise<UsersQueryResponse> => {
  
  return axios
    .post(`${GET_USERS_URL}?${query}`,{
      client_id:id
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (client_id: ID): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}`,
    {
      
      client_id:client_id
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
    
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
  .post(REGISTER_USER_URL, user)
  .then((response: AxiosResponse<Response<User>>) => response.data)
  .then((response: Response<User>) => response.data)
}
const updateStatus = (id:any, status:string): Promise<User | undefined> => {
  return axios
  .post(UPDATE_STATUS_URL, {
    id:id,
    status:status
  })
  .then((response: AxiosResponse<Response<User>>) => response.data)
  .then((response: Response<User>) => response.data)
}

  const updateUser = (user: User): Promise<User | undefined> => {
    return axios
      .post(`${REGISTER_USER_URL}`, {
        type:4,
        name:"muhaddis",
        email:"muhaddisrehman12@gmail.com",
        password:"12345678",
        password_confirmation:"12345678",
        phone:"03244336382",
        street:"2",
        city:"lahore",
        state:"pj",
        zip_code:"50000",
        country:"pk",
        address:"ABZ",
        
    })
}

const deleteProject = (projectId: ID|ID[]): Promise<void> => {
  return axios.post(`${DELETE_PROJECTS_URL}`,{
    id:projectId
  }).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.post(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteProject, deleteSelectedUsers, getUserById, createUser, updateUser, updateStatus}
