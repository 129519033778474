import { ID, Response } from "../../../../../../../../../../_metronic/helpers"

export type User = {
  id?: ID
  street?:                string
  avatar?:                string
  position?:              string
  role?:                  string
  location?:              string
  online?:                boolean
  initials?: {
    label:                string
    state:                string
  }
  type?:                  number;
  name?:                  string;
  company_name?:          string;
  business_category?:     string;
  designation?:           string;
  email?:                 string;
  password?:              string;
  password_confirmation?: string;
  phone?:                 string;
  branch_id?:             string;
  company_address?:       string;
  city?:                  string;
  state?:                 string;
  zip_code?:              string;
  country?:               string;
  contact_person?:        string;
  company_logo?:          File|null|string;
}

export type Client = {
  data?:                  any
  id?:                    ID
  street?:                string
  avatar?:                string
  position?:              string
  role?:                  string
  location?:              string
  online?:                boolean
  initials?: {
    label:                string
    state:                string
  }
  type?:                  number;
  name?:                  string;
  company_name?:          string;
  business_category?:     string;
  designation?:           string;
  email?:                 string;
  password?:              string;
  password_confirmation?: string;
  phone?:                 string;
  branch_id?:             string;
  company_address?:       string;
  city?:                  string;
  state?:                 string;
  zip_code?:              string;
  country?:               string;
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  street: '',
  avatar: '',
  position: '',
  role: '',
  type: 4,
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  phone: '',
  branch_id: '1',
  company_address: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
  company_name: '',
  business_category: '',
  designation: '',
  company_logo: '',


}
