import React from 'react'
import {FC} from 'react'
import { Projects } from '../../core/_models'
type Props = {
    project: Projects
  }
  const ProjectPenCandCell: FC<Props> = ({project}) => {
    const totalCandidates = project!.required;
    let selectedCandidates = project!.selected;
    let pendingCandidates;
    if(selectedCandidates=== null){
        selectedCandidates = 0;
    }
    if(selectedCandidates !== undefined && totalCandidates !== undefined){
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        pendingCandidates = totalCandidates - selectedCandidates;
    }

  return (
    <div>
      {/* {pendingCandidates} */}
      Unassigned
    </div>
  )
}

export default ProjectPenCandCell
