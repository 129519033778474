import {useQueryClient, useMutation} from 'react-query'
import swal from 'sweetalert'
import { QUERIES } from '../../../../../../../../../../../_metronic/helpers'

import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteProject} from '../../core/_requests'

const UsersListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

/////////////////Projec::DEL::FUNC::START////////////////////////////////
  const handleDelNot = ()=>{
    return swal({
      title: "Are you sure?",
      
      icon: "warning",
      buttons:["NO", "Yes"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        delSelectedUsers();
        swal("Poof! Your candidates have been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your candidates are safe!", {
          icon: "success",
        });
      }
    });
  }
  const deleteSelectedItems = useMutation(() => deleteProject(selected[0]), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
      
    },
  })
  const delSelectedUsers = async () => {await deleteSelectedItems.mutateAsync();}
  
/////////////////Projec::DEL::FUNC::END////////////////////////////////

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger ms-2'
        onClick={ handleDelNot}
      >
        Delete
      </button>
    </div>
  )
}

export {UsersListGrouping}
