import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import job_details_icon from '../../../_metronic/assets/icons/job-details.png'
import worker_icon from '../../../_metronic/assets/icons/worker.png'
import mascot_icon from '../../../_metronic/assets/icons/1.png'
import workers_icon from '../../../_metronic/assets/icons/workers.png'
import clients_icon from '../../../_metronic/assets/icons/clients-01.png'
import arrow_right from '../../../_metronic/assets/icons/arrow-right.png'
import jobs_active from '../../../_metronic/assets/icons/jobs-active.png'
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import { UsersQueryResponse } from '../../modules/apps/worker-management/workers-list/core/_models'
import { useEffect, useState } from 'react'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ADMIN_DASHBOARD_URL = `${API_URL}/admin-dashboard`


   

const DashboardPage = () => {
  
  const [adminResponse, setAdminResponse] = useState<any>({});
  const [pendingProjects, setPendingProjects] = useState<number>(0);
  const [declinedProjects, setDeclinedProjects] = useState<number>(0);
  const [ongoingProjects, setOngoingProjects] = useState<number>(0);
  const [workerRequests, setWorkerRequests] = useState<number>(0);
  const [totalClients,  setTotalClients] = useState<number>(0);
  const [activeWorkers, setActiveWorkers] = useState<number>(0);
  
  useEffect(() => {
    const fetchData = async () => {
      const response:AxiosResponse = await axios.post(ADMIN_DASHBOARD_URL);
      const data = response.data;
      setAdminResponse(data);
      setPendingProjects(data?.pending_projects);
      setDeclinedProjects(data?.declined_projects);
      setOngoingProjects(data?.ongoing_projects);
      setWorkerRequests(data?.requested_workers);
      setTotalClients(data?.total_clients);
      setActiveWorkers(data?.active_workers);
    };
    fetchData();
  }, []);
  
  const labels = ['Approved', 'Pending', 'Declined'];
  const data = [ongoingProjects, pendingProjects, declinedProjects];
  const backgroundColor = ['#00ce81', 'orangered', 'yellow'];
  const hoverBackgroundColor = ['darkred', 'darkblue', 'goldenrod'];

  return (
    <>
      {/* begin::Row  */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col  */}
        <div className='col-md-8 welcome_widget'>
          
            <div className="d-flex">
              <div className=" ">
                <h1>Welcome, {sessionStorage.getItem("name")}</h1>
                <p>We have {pendingProjects} new applications. It is a lot of work for today! So let's start. </p>
              </div>

                <img src={mascot_icon} alt="a woman using a laptop" />

            </div>
        </div>
        {/* end::Col  */}
        <div className='col-md-4 d-flex call_to_action_btns card_shadow ms-4'>

              <button className=' call_to_action_btn'>
            <Link to={`/Projects-Posted/Add-Project`}>
                Add a Job
            </Link>
              </button>
              <button className=' call_to_action_btn'>
            <Link to={`/apps/clients-management/clients`}>
                Add a Client
            </Link>
              </button>
        </div>
        {/* end::Col  */}
        </div>
        <div className="row  second_row">
          {/* begin::Col  */}
          <div className='col-md-3 base_card details_widget justify-content-center align-item-center pt-5'>
          
            <div className='d-flex mt-5'>
          
              <img src={job_details_icon} alt="job-details" />
              <div>
            <h1>{pendingProjects}+ </h1>
              <p>Job Requests</p>
              </div>
            </div>
            <div className='d-flex'>
              <img src={worker_icon} alt="workers detail" />
              <div>
                <h1>{workerRequests}+</h1>
                <p>Worker Requests</p>
              </div>
            </div>
          
          </div>
          {/* end::Col  */}
          <div className='col-md-5   actives_widget'>
              <div>
                <div className="tab col-md-10 mb-4">
                  
                  <Link to="/Project-management/projects">
                      <div>
                          <img src={jobs_active} alt="" />
                          <div className='active_details'>
                            <h4>{ongoingProjects}</h4>
                            <p>Live Jobs</p>
                          </div>
                        </div>
                  </Link>
                  <Link to="/Project-management/projects">
                      <div className='arrow'>
                        <img src={arrow_right} alt="" />
                      </div>
                  </Link>
                </div>
                <Link to="/apps/clients-management/clients">
                <div className="tab col-md-10 mb-4">
                  
                    <div>
                      <img src={clients_icon} alt="" />
                      <div className='active_details'>
                        <h4>{totalClients}</h4>
                        <p>Clients</p>
                      </div>
                    </div>
                  
                  
                    <div className='arrow'>
                        <img src={arrow_right} alt="" />
                    </div>
                  
                </div>
                </Link>
                  <Link to={`/apps/worker-management/${undefined}/workers`}>
                  <div className="tab col-md-10">
                      <div>
                        <img src={workers_icon} alt="" />
                        <div className='active_details'>
                          <h4>{activeWorkers}</h4>
                          <p>Workers Active</p>
                        </div>
                      </div>
                  
                      <div className='arrow'>
                        <img src={arrow_right} alt="" />
                      </div>
                  </div>
                  </Link>
              </div>
          </div>
          {/* end::Col  */}
           {/* end::Col  */}
          <div className='col-md-4 detail_chart'>
              
              <div className='chart_heading'>
                Job Aproval Tracking
              </div>
              <div className='chart_body d-flex justify-content-center'>
                <div>
                  <DoughnutChart
                        labels={labels}
                        data={data}
                        backgroundColor={backgroundColor}
                        hoverBackgroundColor={hoverBackgroundColor}
                  />
                </div>
              </div>

          </div>
          {/* end::Col  */}

        </div>
      {/* end::Row */}
    </>
  )
}
const DoughnutChart = (props:any) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: props.backgroundColor,
        hoverBackgroundColor: props.hoverBackgroundColor,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    color:'white',
    legend: {
      position: 'right',
      
    },
    // circumference: Math.PI,
    // rotation: -Math.PI,
    tooltips: {
      callbacks: {
        label: function (tooltipItem:any, data:any) {
          let label = data.labels[tooltipItem.index];
          let value = data.datasets[0].data[tooltipItem.index];
          return label + ': ' + value;
        },
      },
    },
  };
  return <Doughnut data={data} options={options} />;
};

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
