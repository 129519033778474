import React, { FC } from 'react'
import { User } from '../../core/_models'

type Props = {
    user:User,
}
const AppliedWorkerAgencyBranchCell:FC<Props> = ({user}) => {
  return (
    <div>Agency1 BranchB
    </div>
  )
}

export default AppliedWorkerAgencyBranchCell