import { ID, Response } from "../../../../../../_metronic/helpers"


export type User = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  skills?:string
  initials?: {
    label: string
    state: string
  }
  phone?:string
  status?: string
  joined_at?: Date
  location?: string
  projects_taken?:string
  city?:string
  country?:string
  cv?:{
    id:ID
    skills?:any
  }
  user?:any
}
export type CV = {
  data?:{
    0?:{
      id?: number
      user_id?:number
      skills?:string
      languages?:string
      about_me?:string
      created_at?:string
      education?:{
        0?:{
          id?:number
          user_id?:number
          qualification?:string
        }
      }
      }
    }
  }

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  location:'Pakistan, Lahore',
}
