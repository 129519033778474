import React, { FC } from 'react'
import { User } from '../../core/_models'
type Props = {
    user: User
}
const WorkerIDCell:FC<Props> = ({user}) => {
    console.log("🚀 ~ file: WorkerIDCell.tsx:10 ~ user?.user?.id:", user?.user_id)

    return (
        <div>{user?.user?.id}</div>
    )
}

export default WorkerIDCell