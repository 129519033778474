import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {CV, User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CV_URL = `${API_URL}/get-cv`
const USER_URL = `${API_URL}/get-job-seekers`
const GET_USERS_URL = `${API_URL}/get-job-seekers`
const DEL_USERS_URL = `${API_URL}/del-job-seeker`
const NOTIFY_USERS_URL = `${API_URL}/notify-job-Seeker`
const MAIL_USERS_URL = `${API_URL}/send-mail`
const SAVE_USERS_URL = `${API_URL}/save-jobSeeker`
const REGISTER_USER_URL = `${API_URL}/register`
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .post(`${GET_USERS_URL}?${query}`,{
      limit:10
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${REGISTER_USER_URL}`, {
      type:4,
      name:"muhaddis",
      email:"muhaddisrehman12@gmail.com",
      password:"12345678",
      password_confirmation:"12345678",
      phone:"03244336382",
      street:"2",
      city:"lahore",
      state:"pj",
      zip_code:"50000",
      country:"pk",
      address:"ABZ",
      
  })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.post(DEL_USERS_URL,{
    id:userId
  }).then(() => {})
}


const getUserCV = async (userId: ID | number): Promise<void|CV> => {
  return axios
    .post(CV_URL, {id:userId})
    .then((response: AxiosResponse<Response<CV>>) => response.data)
    .then((response: Response<CV>) => response.data) 

}


const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.post(DEL_USERS_URL,{
    id:id
  }))
  return axios.all(requests).then(() => {})
}
const notifySelectedUsers = (userIds: Array<ID>, message:string): Promise<void> => {
  const requests = userIds.map((id) => axios.post(`${NOTIFY_USERS_URL}`, {
    id:id,
    topic:"message",
    message: message
  }))
  return axios.all(requests).then(() => {})}
  const mailSelectedUsers = (userIds: Array<ID>, message:string): Promise<void> => {
    const requests = userIds.map((id) => axios.post(`${MAIL_USERS_URL}`, {
      id:id,
      message: message
    }))
    return axios.all(requests).then(() => {})}
const notifyUser = (userId: ID, message:string): Promise<ID> => {
    return  axios.post(`${NOTIFY_USERS_URL}`, {
      id:userId,
      topic:"message",
      message: message,
    }) }
const mailUser = (userId: ID, message:string): Promise<ID> => {
      return  axios.post(`${MAIL_USERS_URL}`, {
        id:userId,
        message: message,
      })}
const saveUser = (userIds: Array<ID>): Promise<void> =>{
  const requests = userIds.map((id) => axios.post(`${SAVE_USERS_URL}`, {
    user_id: id,
  }))
  return axios.all(requests).then(() => {})}
export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, notifySelectedUsers, getUserCV, notifyUser, mailUser, saveUser, mailSelectedUsers}
