/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'
import { Link, useParams } from 'react-router-dom'
import { ID } from '../../../../../../../../../../../_metronic/helpers'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'



type Props = {
  project: Projects
}

const ProjectInfoCell: FC<Props> = ({project}) => {
  const routeParams = useParams()
  
  const client_id = routeParams?.id

  const projectId:ID = project?.id!;
  return(
  <div className='d-flex align-items-center'>
      <Link to={`/clients-management/clients/client-project/${client_id}/${projectId}`}>
        {project?.title}
      </Link>
    
  </div>
  )
}

export {ProjectInfoCell}
