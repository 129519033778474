import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import React from 'react'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import WorkersPage from '../modules/apps/worker-management/WorkersPage'

import ClientDetailsPage from '../modules/apps/clients-management/clients-list/table/columns/ClientDetailsPage'
import ProjectsPage from '../modules/apps/projects-management/ProjectsPage'
import ClientProjectDetails from '../modules/apps/clients-management/clients-list/table/columns/ClientProjectDetails'
import ProjectsPostedPage from '../modules/apps/projects-posted/PostedProjectsPage'

import AddClientModal from '../modules/apps/clients-management/clients-list/components/header/UserListToolbar'
import ClientPortalPage from '../pages/client-portal/ClientPortalPage'
import AddProjectWrapper from '../modules/apps/projects-management/addProject/AddProjectWrapper'
import ApproveProjectWrapper from '../modules/apps/projects-management/approve-project/ApproveProjectWrapper'



const PrivateRoutes = () => {
  const ProfilePage = React.lazy(() => import('../modules/profile/ProfilePage'))



  const ClientsPage = lazy(() => import('../modules/apps/clients-management/clientsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        
        {/* Redirect to Dashboard after success login/registartion and the user is admin*/}
        {
          sessionStorage.getItem('type')==="1"&&
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            <Route path='dashboard' element={<DashboardWrapper />} />

        {/* Pages */}
        
        
      
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/clients-management/clients/client-profile/:id'
          element={
            <SuspensedView>
              <ClientDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/clients-management/clients/client-project/:client_id/:projectId'
          element={
            <SuspensedView>
              <ClientProjectDetails/>
            </SuspensedView>
          }
        />
        <Route
          path='/clients-management/clients/client-profile/client-project/*'
          element={
            <SuspensedView>
              <ClientsPage/>
            </SuspensedView>
          }
        />
        <Route
          path='apps/projects-management/*'
          element={
            <SuspensedView>
              <ProjectsPage/>
            </SuspensedView>
          }
        />
        <Route
          path='apps/clients-management/*'
          element={
            <SuspensedView>
              <ClientsPage/>
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/worker-management/:project_id/*'
          element={
            <SuspensedView>
              <WorkersPage />
            </SuspensedView>
          }/>
          
        <Route
          path='/Project-management/*'
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          }
          
        />
        <Route
          path='/Projects-Posted/*'
          element={
            <SuspensedView>
              <ProjectsPostedPage />
            </SuspensedView>
          }/>

        
        <Route
          path='/Projects-Posted/Add-Project'
          element={
            <SuspensedView>
              <AddProjectWrapper />
            </SuspensedView>
          }/>
        <Route
          path='/Projects-Posted/Approve-Project/:client_id/:project_id'
          element={
            <SuspensedView>
              <ApproveProjectWrapper/>
            </SuspensedView>
          }/>

        <Route
          path='Client-Management/Add-Client'
          element={
            <SuspensedView>
              <AddClientModal />
            </SuspensedView>
          }/>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
       </>
        }
        {
          sessionStorage.getItem('type') === "4" &&<>
          {/* Redirect to Client Portal after successful login and the user is a client*/}
          <Route path='/' element={<Navigate to="/clientPortal"/>}/>
          <Route path='auth/*' element={<Navigate to='/clientPortal' />} />
          <Route path='clientPortal' element={<ClientPortalPage />} />
          <Route path='*' element={<Navigate to='/error/404' />} />

          {/* ADD PROJECT ROUTE */}
          <Route
          path='/Projects-Posted/Add-Project'
          element={
            <SuspensedView>
              <AddProjectWrapper />
            </SuspensedView>
          }/>
        </>
        }
      </Route>
      
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
