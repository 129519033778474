import React, { useEffect, useState } from 'react'
import ClientPortalHeader from './ClientPortalHeader'
import './ClientPortal.css'
import defaultAvatar from '../../../_metronic/assets/icons/300-1.jpg'
import axios from 'axios'
import ClientPortalBody from './ClientPortalBody'
const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_SELECTED_USERS_URL = `${API_URL}/get-selected-jobseekers`
const GET_CLIENT_PROJECTS = `${API_URL}/get-client-projects`
const ClientPortalPage = () => {
  const [selectedjobseekers, setSelectedjobseekers] = useState<Array<any>>()
  const [relatedProjects, setRelatedProjects] = useState<Array<any>>()
  const [projectId, setProjectId] = useState<number>()
  useEffect(() => {
    axios
      .post(GET_SELECTED_USERS_URL, { project_id: projectId })
      .then((d) => setSelectedjobseekers(d.data?.success?.data))
  }, [projectId]);
  
  useEffect(() => {
    axios
      .post(GET_CLIENT_PROJECTS, { client_id: sessionStorage.getItem("id") })
      .then((d) => setRelatedProjects(d.data?.success?.data))
  }, []);
  


  return (
    <>
        <ClientPortalHeader/>
        <ClientPortalBody/>
       
    </>
  )
}

export default ClientPortalPage

