import React from 'react'
import {FC} from 'react'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'

type Props = {
    project: Projects
  }
const ProjectBranchCell: FC<Props> = ({project}) => {
  return (
    <div>{project?.branch}</div>
  )
}

export default ProjectBranchCell