import React from 'react'
import {FC} from 'react'
import {Projects} from '../../core/_models'

type Props = {
    project: Projects
  }

const ProjectSelCandCell: FC<Props> = ({project}) => {
  return (
    <div>
      {(project?.rate_hour)?project?.rate_hour:"0"}
    </div>
  )
}

export default ProjectSelCandCell
