/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'
import { Link } from 'react-router-dom'

import {Projects} from '../../core/_models'

type Props = {
  project: Projects
}

const ProjectInfoCell: FC<Props> = ({project}) => {
  const client_id = project?.client_id
  const project_id = project?.id
  return(
  <Link to={`/Projects-Posted/Approve-Project/${client_id}/${project_id}`}>
    <div className='d-flex align-items-center'>
        {project?.title}
    </div>
  </Link>
  )
}

export {ProjectInfoCell}
