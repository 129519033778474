/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'
import { Link } from 'react-router-dom'
import { ID } from '../../../../../../../_metronic/helpers'

import {Projects} from '../../core/_models'

type Props = {
  project: Projects
}

const ProjectInfoCell: FC<Props> = ({project}) => {
  const client_id = project?.client_id;
  const projectId:ID = project?.id!;
  return(
    <Link to={`/clients-management/clients/client-project/${client_id}/${projectId}`}>
      {project?.title}
    </Link>
  )
}

export {ProjectInfoCell}
