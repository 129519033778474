import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {OfferedWorkersTable} from './table/OfferedWorkersTable'
import {OfferedWorkersEditModal} from './offered-workers-edit-modal/OfferedWorkersEditModal'
import { KTCard } from '../../../../../_metronic/helpers'



const OfferedWorkersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <OfferedWorkersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OfferedWorkersEditModal />}
    </>
  )
}

const OfferedWorkersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OfferedWorkersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default OfferedWorkersListWrapper
