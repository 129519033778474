import React, { FC } from 'react'
import { User } from '../../core/_models'
type Props = {
    user: User
}

const UserAgencyCell: FC<Props> = ({user}) =>{
    return (
    <div className="d-flex align-items-center">
        Agency Name
    </div>
    )
}

export default UserAgencyCell
