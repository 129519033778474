import React, { useEffect, useState } from 'react'
import './ClientPortal.css'
import defaultAvatar from '../../../_metronic/assets/icons/300-1.jpg'
import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_SELECTED_USERS_URL = `${API_URL}/get-selected-jobseekers`
const GET_CLIENT_PROJECTS = `${API_URL}/get-client-projects`
const ClientPortalBody = () => {
  const [selectedjobseekers, setSelectedjobseekers] = useState<Array<any>>()
  const [relatedProjects, setRelatedProjects] = useState<Array<any>>()
  const [projectId, setProjectId] = useState<number>()
  useEffect(() => {
    axios
      .post(GET_SELECTED_USERS_URL, { project_id: projectId })
      .then((d) => setSelectedjobseekers(d.data?.success?.data))
  }, [projectId]);
  
  useEffect(() => {
    axios
      .post(GET_CLIENT_PROJECTS, { client_id: sessionStorage.getItem("id") })
      .then((d) => setRelatedProjects(d.data?.success?.data))
  }, []);
  


  return (
    <>
        {/* PROJECTS::NAMES::START */}
        <section className='mb-5'>
          {/*begin::Label*/}
          <label className="fs-6 fw-semibold mb-2">Your Jobs:</label>
          {/*end::Label*/}
          <select
            name="projectName"
            id="projectName"
            data-control='select2'
            data-hide-search='true'
            onChange={(e:any)=>{setProjectId(e.target.value)}}
            className='form-select form-select-white form-select-md w-200px'
            >
                <option value="">Select a Job</option>
            {relatedProjects?relatedProjects?.map((projects, key)=>{
              return(<option key={key}value={projects.id}>{projects?.title} - {projects?.branch}</option>)
            })
          
            :<option value="">No Projects</option>
          
            }
          </select>
        </section>
        {/* PROJECTS::NAMES::END */}

        {/* WORKERS::CARDS::START */}
        <section>
          <div className="row gx-5">
            {selectedjobseekers?
              selectedjobseekers?.map((worker, key)=>{
                return(
                    <div className="col-lg-4 worker_card text-center rounded"key={key}>
                      <div className=' position-relative rounded-circle mb-6' >
                        <img src={defaultAvatar} className="avatar"alt='Metornic' />
                        <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                      </div>
                        {/* <img src={defaultAvatar} alt="worker avatar" /> */}
                        <h3>{worker?.name}</h3>
          
                          <p className="text-gray-400">{worker?.phone}</p>
                          <p className="text-gray-400">{worker?.email}</p>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4'>
                            <div className='d-flex align-items-center justify-content-center'>
                              <div className='fs-2 fw-bolder'>35</div>
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Total Jobs Taken</div>
                        </div>
                      </div>
                )
          
              }):<p>No Selected Workers</p>}
          </div>
        </section>
        {/* WORKERS::CARDS::END */}
    </>
  )
}

export default ClientPortalBody

