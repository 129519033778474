import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/get-projects`
const GET_USERS_URL = `${API_URL}/get-projects`
const UPDATE_PROJECT_URL =`${API_URL}/project_update`
const ADD_PROJECT_URL = `${API_URL}/add-project`
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .post(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getProjectById = (id: ID): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateProject = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${UPDATE_PROJECT_URL}`,{

    }
    )
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}
const addProject = (project: any): Promise<User | undefined> => {
  return axios
    .post(`${ADD_PROJECT_URL}`, {
                contact_person:project?.contact_person,
                client_id: project?.client_id,
                category_id: project?.category_id,
                title: project?.title,
                description: project?.description,
                rate_hour: project?.rate_hour,
                branch: project?.branch,
                required: project?.required,
                selected: project?.selected,
                required_at: project?.required_at,
                responsibilities: project?.responsibilities,
                working_hours: project?.working_hours,
                hiring_type: project?.hiring_type,
                status: project?.status,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}
const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser,addProject, deleteSelectedUsers, getProjectById, createUser, updateUser, updateProject}
