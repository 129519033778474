import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UsersListWrapper} from './users-list/UsersList'
import { Tab, Tabs } from 'react-bootstrap'
import { useState } from 'react'
import { ProjectsPostedListWrapper } from '../projects-posted/posted-projects-list/UsersList'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Job Rack',
    path: '/apps/projects-management/projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  const [defaultPage, setDefaultPage] = useState("Listed")
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='projects/*'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{defaultPage} Jobs</PageTitle>
              <Tabs
          defaultActiveKey={"Listed"}
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={defaultPage}
          onSelect={(k:any) => setDefaultPage(k)}
        >
        <Tab eventKey="Listed" title="Listed">  
          {
          defaultPage==="Listed" && <>
          <UsersListWrapper />
          </>
          }
            
        </Tab>
        <Tab eventKey="Posted" title="Posted">
          {defaultPage==="Posted" && <ProjectsPostedListWrapper/>}
        </Tab>
        </Tabs>
              
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/projects-management/' />} />
    </Routes>
  )
}

export default UsersPage
