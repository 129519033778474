import React, { FC } from 'react'
import { User } from '../../core/_models'

type Props = {
    user:User,
}
const AppliedWorkersDateAppliedCell:FC<Props> = ({user}) => {
  const dateApplied = new Date(user?.updated_at)
  return (
    <div>{dateApplied.toLocaleDateString()}</div>
  )
}

export default AppliedWorkersDateAppliedCell