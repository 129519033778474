import React, { FC } from 'react'
import { User } from '../../core/_models'
type Props = {
    user: User
}
const WorkerIDCell:FC<Props> = (user) => {

    return (
        <div>{user?.user?.id}</div>
    )
}

export default WorkerIDCell