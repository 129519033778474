import React from 'react'
import {FC} from 'react'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'

type Props = {
    project: Projects
  }
const ProjectClientCell: FC<Props> = ({project}) => {
  return (
    <div>
      {project?.id}
    </div>
  )
}

export default ProjectClientCell
