
import {UsersListFilter} from './UsersListFilter'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import * as XLSX from 'xlsx'
import {  KTSVG } from '../../../../../../../_metronic/helpers'

const UsersListToolbar = () => {
  
  const users = useQueryResponseData()
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "MyClients.xlsx")
  }

  return (
    <div className='d-flex justify-content-end align-items-center' data-kt-user-table-toolbar='base'>
      
    
      {/* begin::Export */}
      <button 
              type='button' 
              className='btn btn-light-primary me-0'
              onClick={handleOnExport}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>


      
      
    </div>
  )
}

export {UsersListToolbar}
