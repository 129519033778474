import React from 'react'
import {User} from '../../core/_models'
import {FC} from 'react'
type Props = {
    user: User
}
const UserStatusCell: FC<Props> = ({user}) =>{
    
    const status = user?.offer_status!;
    

    
    
    return(
        <div>{status}</div>
    )
}

export  {UserStatusCell}