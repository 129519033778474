/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
// import axios, { AxiosResponse } from 'axios'
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { MydModalWithGrid } from '../../../../workers-requests-management/workers-list/table/columns/UserCVCell'



// import { Link } from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'


type Props = {
  user: User
}

// const CV_URL = `${API_URL}/get-cv`


//Start Component

const AppliedWorkersInfoCell: FC<Props> = ({user}) => {
  const [response1, setResponse] = useState(Object)
  const [modalShow, setModalShow] = useState(false);
  const education = response1[0]?.education
  const experience = response1[0]?.experience
  const cV = response1[0]
//     useEffect(() => {
//         if (isNotEmpty(user.cv)){
//           const positionString = (user.cv?.skills!).replace("[", "").replace("]", "").split(",")
//           setSkills(positionString!)
//         }
    
//         axios
//         .post(CV_URL, {
//             id:user.id
//         })
//         .then((response: AxiosResponse<Response<CV>>) => response.data)
//         .then((response: Response<CV>) => setResponse(response.data)) 
//     }, [user.id, user.cv]
// )
    
    


  const [skills, setSkills] = useState<Array<string>>();
  const [modalStatus, setModalStatus] = useState<boolean|undefined>(false);
  const [Status, setStatus] = useState<String>("")
  const status = user.user?.availability;
    
    useEffect(() => {
      if (status === 1){

        // <div className={"badge p-3 badge-light-danger"} style={{fontSize:"12px"}}>Inactive</div>
        setStatus("Inactive")
    }else if (status === 0){
      setStatus("Active")
    }
    else if (status === 2){
      setStatus("Occupied")
    }
    
      
    }, [status])
    
return (
  
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    {/* <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
            {user.initials?.label}
          </div>
        )}
      </a>
    </div> */}
    <div  className='d-flex text-primary flex-column justify-content-center align-items-center cursor-pointer'
          onClick={()=> {
                    setModalStatus(true)
                    console.log(modalStatus)
                    // handleDetails();
                  }
                    }>
      {user.name}
    </div>
    <Modal
        size="lg"
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Candidate's Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MydModalWithGrid 
                            show={modalShow} 
                            onHide={() => setModalShow(false)} 
                            user_id = {user.id}
                            user_name = {user.name}
                            phone = {user.phone}
                            email = {user.email}
                            city = {user.city}
                            country = {user.country}
                            about_me= {response1[0]?.about_me}
                            education= {education}
                            experience = {experience}
                            cv={cV}
                            />
          <div className="container">
            {/* First Row */}
            <div className="row my-3">
              <div className="col-4 ">
                 {/* begin:: Avatar */}
                <div className='symbol symbol-circle symbol-150px overflow-hidden me-3'>
                  <a href='#'>
                    {user.avatar ? (
                      <div className='symbol-label'>
                        <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='' />
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          'symbol-label fs-3',
                          `bg-light-${user.initials?.state}`,
                          `text-${user.initials?.state}`
                        )}
                      >
                        {user.initials?.label}
                      </div>
                    )}
                  </a>
                </div>
              </div>
              <div className="col-5 ">
                <div><h1>{user?.name}</h1></div>
                
                <p>{response1[0]?.experience[0].designation}</p>
                <p>Joined Since:mm/dd/yyyy{user?.joined_day}</p>
                
              </div>
              <div className="col-3">
                <h1>Status</h1>
                <p>{Status}</p>
                CV <button className="btn btn-primary px-3 py-1 ms-2"onClick={() => setModalShow(true)}>View</button>
              </div>
            </div>
            {/*Second Row */}
            <div className="row my-3">
              <div className="col-6">
                <h1>
                  Skills
                </h1>
                <ul>
                {skills?.map((skill, key)=>{
                  return(
                    <li key={key}>{skill}</li>
                  )
                })}
                </ul>
              </div>
              <div className="col-6">
              <h1>
                  Branch Assigned
                </h1>
                <ul>
                {skills?.map((skill, key)=>{
                  return(
                    <li key={key}>{skill}</li>
                  )
                })}
                </ul>
              </div>
            </div>
             {/*Third Row */}
            <div className="row my-3">
              <div className="col-6">
                <h1>
                  Ongoing Projects
                </h1>
                <ul>
                  <li>Software</li>
                  <li>Plumbing</li>
                  <li>Product Marketing</li>
                  <li>Project Management</li>
                </ul>
              </div>
              <div className="col-6">
                      
              </div>
            </div>
             {/*Fourth Row */}
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
</div>
  
)
}

export {AppliedWorkersInfoCell}
