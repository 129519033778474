import React from 'react'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import AddProjectAdmin from './AddProjectAdmin'

import AddProjectClient from './AddProjectClient'


const AddProjectWrapper = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
          title: 'Posted Projects',
          path: '/apps/projects-management/projects',
          isSeparator: false,
          isActive: false,
        },
        {
          title: '',
          path: '',
          isSeparator: true,
          isActive: false,
        },
      ]
  return (
    <>
    <PageTitle breadcrumbs={usersBreadcrumbs}>Add Job</PageTitle>
    {sessionStorage.getItem("type")==="4" && <AddProjectClient/>}

    {sessionStorage.getItem("type")==="1" &&<AddProjectAdmin/>}
    </>
  )
}

export default AddProjectWrapper