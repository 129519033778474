/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'


import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      {sessionStorage.getItem('type')==="1"&&<>
      
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-bar-chart-line'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-3'
      />
      <AsideMenuItem
        to={`/apps/worker-management/${undefined}/workers`}
        title='Workers'
        fontIcon='bi-person'
        bsTitle='Workers'
        className='py-3'
      />
      
      <AsideMenuItem
        to='/apps/clients-management/clients'
        title='Clients'
        fontIcon='bi-people'
        bsTitle='Clients'
        className='py-3'
      />
      <AsideMenuItem
        to='/Project-management/projects'
        title='Jobs'
        fontIcon='bi-file-text'
        bsTitle='Jobs'
        className='py-3'
      />
      </>}
      {sessionStorage.getItem('type')==="4"&&<>
        <AsideMenuItem
         to='/clientPortal'
         title='Client Portal'
         fontIcon='bi-file-text'
         bsTitle='Client Portal'
         className='py-3'
         /></>}
    </>
  )
}
