import {FC} from 'react'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'

type Props = {
    project: Projects
  }
const ProjectReqCandCell: FC<Props> = ({project}) => {
  return (
    <div>
      {project?.required}
    </div>
  )
}

export default ProjectReqCandCell
