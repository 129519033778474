import React from 'react'
import {User} from '../../core/_models'
import {FC} from 'react'
type Props = {
    user: User
}
    

const UserContactCell: FC<Props> = ({user}) =>{
    // console.log("🚀 ~ file: UserContactCell.tsx:7 ~ user", user.phone)
    return (
    <div className="d-flex justify-content-center flex-column">
        {user.phone}
        <div>
            {user.email}
        </div>
    </div>
    )
}

export  {UserContactCell}