import React from 'react'
import {User} from '../../core/_models'
import {FC} from 'react'
type Props = {
    user: User
}
const UserStatusCell: FC<Props> = ({user}) =>{
    
    const status = user?.status!;
    console.log("workers", status)


    
    if (status === "1"){
    return (
    <div className={"badge p-3 badge-light-danger"} style={{fontSize:"12px"}}>Inactive</div>
    )
    }else if (status === "0"){
        return(
            <div className={"badge p-3 badge-light-danger"} style={{fontSize:"12px"}}>Unapproved</div>
        )
    }
    else if (status === "2"){
        return(
            <div className={"badge p-3 badge-light-warning"} style={{fontSize:"12px"}}>Occupied</div>
        )
    }
    return(
        <div>Invalid</div>
    )
}

export  {UserStatusCell}