/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import swal from 'sweetalert'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { approveJobseeker } from '../../../../applied-workers/workers-list/core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'




import { User } from '../../core/_models'
import { confirmOffer } from '../../core/_requests'
type Props = {
  id: ID,
  user:User
}

const UserActionsCell: FC<Props> = ({id, user}) => {




  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  
  const userId= user?.offer_id

  const handleApprove =async()=>{
    await approveSelectedJobseekers.mutateAsync()
    swal({
      title: "Approved Jobseeker successfully.",
      icon: "success",
    })
}
  const approveSelectedJobseekers = useMutation(() => confirmOffer(userId), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      
    },
    
  })
  return (
    <div className="d-flex">
    
    {user?.offer_status==="accepted"?<>
      <Button
            variant="primary"
            className={" me-2 text-center"}
            onClick={handleApprove}
            >
                  Confirm
        </Button>
        
    </>:<div className='badge badge-light-warning'>
    {user?.offer_status}
        </div>}
      
      
    </div>
  )
}

export {UserActionsCell}
