import axios from 'axios'
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CLIENT_URL = `${API_URL}/get-clients`
const UsersListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [client, setClient] = useState<string | undefined>()
  const [hiringType, setHiringType] = useState<string | undefined>()
  const [status, setStatus] = useState<string | undefined>()
  const [clientName, setClientName] = useState<any>()

  // This code uses the useEffect hook in React to call the MenuComponent.reinitialization() function when the component is first rendered. This function will be called every time the component is rendered, ensuring that the menu is always up to date.
useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {status: status, client_id: client, hiring_type:hiringType},
      ...initialQueryState,
    })
  }
  useEffect(() => {
    const fetchData = async () => {
        const [clientResponse ] = await Promise.all([
        axios.post(GET_CLIENT_URL),
      
        ]);
        setClientName(clientResponse.data?.success?.data);
        
    };
    fetchData();
    }, []);
  return (
    <>
      {/* begin::Filter Button */}
      {/* <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button> */}
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      
        {/* begin::Header */}
        {/* <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div> */}
        {/* end::Header */}

        {/* begin::Separator */}
        {/* <div className='separator border-gray-200'></div> */}
        {/* end::Separator */}

        {/* begin::Content */}
        {/* <div className='px-7 py-5' > */}
          {/* begin::Input group */}
          <div className='px-5'>
            <label className='form-label fs-6 fw-bold'>By Employer:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setClient(e.target.value)}
              value={client}
              >
              <option value=''>All</option>
              {clientName && clientName.length > 0 ? clientName.map((clients: any, key: any)=>{
                        return(
                            <option value={clients.user_id} key={key}>{clients.name}</option>
                        )
                    }) : null}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb'>
            <label className='form-label fs-6 fw-bold'> Assigned to:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              // onChange={(e) => set(e.target.value)}
              // value={lastLogin}
            >
              <option value=''>All</option>
              <option value='Branch'>Branch</option>
              <option value='Branch1'>Branch 1</option>
              <option value='Branch2'>Branch 2</option>
              <option value='Branch3'>Branch 3</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value=''>All</option>
              <option value='opened'>Opened</option>
              <option value='closed'>Closed</option>
              <option value='complete'>Successful</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-'>
            <label className='form-label fs-6 fw-bold'>Hiring Type:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setHiringType(e.target.value)}
              value={hiringType}
            >
              <option value=''>All</option>
              <option value='Temp'>Temp</option>
              <option value='Hire'>Hire</option>
              <option value='Temp to hire'>Temp to Hire</option>
              <option value='Contract'>Contract</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-'>
            <label className='form-label fs-6 fw-bold'>Sector:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              // onChange={(e) => setLastLogin(e.target.value)}
              // value={lastLogin}
            >
              <option value='all'>All</option>
              <option value='sector1'>Sector1</option>
              <option value='sector2'>Sector2</option>
              <option value='sector3'>Sector3</option>
              <option value='sector4'>Sector4</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={ filterData}
              className='btn btn-primary fw-bold '
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        {/* </div> */}
        {/* end::Content */}
      {/* </div> */}
      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
