import React from 'react'
import {User} from '../../core/_models'
import {FC} from 'react'
import { isNotEmpty } from '../../../../../../../_metronic/helpers'
type Props = {
    user: User
}
const UserSkillsCell: FC<Props> = ({user}) =>{
    
    if (isNotEmpty(user.cv)){
    const positionString = (user.cv?.skills).replace("[", "").replace("]", "").split(",")
    return(
        <>{positionString[0]}</>
    )
    }
    return(
        <>no cv</>
    )
    

}

export  {UserSkillsCell}
// return(
//     <ul>{
//         newPositionString.map((key:any)=>{
//             return(
//             <li>{key} </li>
//         )})
//     }</ul>
// )