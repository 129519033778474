import {Column} from 'react-table'
import {SelectedWorkersInfoCell} from './SelectedWorkersInfoCell'
// import {SelectedWorkersLastLoginCell} from './SelectedWorkersLastLoginCell'
// import {SelectedWorkersTwoStepsCell} from './SelectedWorkersTwoStepsCell'
import {SelectedWorkersActionsCell} from './SelectedWorkersActionsCell'
import {SelectedWorkersSelectionCell} from './SelectedWorkersSelectionCell'
import {SelectedWorkersCustomHeader} from './SelectedWorkersCustomHeader'
import {SelectedWorkersSelectionHeader} from './SelectedWorkersSelectionHeader'
import {User} from '../../core/_models'
import {SelectedWorkersContactCell} from './SelectedWorkersContactCell'
import { SelectedWorkersSkillsCell } from './SelectedWorkersSkillsCell'
import { SelectedWorkersStatusCell } from './SelectedWorkersStatusCell'
import { SelectedWorkersLocationCell } from './SelectedWorkersLocationCell'
import SelectedWorkersCVCell from './SelectedWorkersCVCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectedWorkersSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectedWorkersSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <SelectedWorkersCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'candidate',
    Cell: ({...props}) => <SelectedWorkersInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Contact' className='min-w-125px' />
    ),
    id:"contact",
    Cell: ({...props}) => <SelectedWorkersContactCell user={props.data[props.row.index]} />
  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Skills' className='min-w-125px' />
    ),
    id:"skills",
    Cell: ({...props}) => <SelectedWorkersSkillsCell user={props.data[props.row.index]} />
  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id:"status",
    Cell: ({...props}) => <SelectedWorkersStatusCell user={props.data[props.row.index]} />

  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Agency/Branch' className='min-w-125px' />
    ),
    id: 'agencyBranch',
    Cell: ({...props}) => <SelectedWorkersLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <SelectedWorkersLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='CV' className='min-w-125px' />
    ),
    id: 'cv',
    Cell: ({...props}) => <SelectedWorkersCVCell user={props.data[props.row.index]} />,
  },  
  {
    Header: (props) => (
      <SelectedWorkersCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SelectedWorkersActionsCell user={props.data[props.row.index]}id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
