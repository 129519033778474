import React from 'react'
import {FC} from 'react'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'


type Props = {
    project: Projects
  }

const ProjectSelCandCell: FC<Props> = ({project}) => {
  return (
    <div>
      {(project?.selected)?project?.selected:"none"}
    </div>
  )
}

export default ProjectSelCandCell
