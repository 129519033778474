import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers, mailSelectedUsers, notifySelectedUsers, saveUser} from '../../core/_requests'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react'
import { useState } from 'react'
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router'
import { getRelatedJobSeekersById } from '../../../../clients-management/clients-list/table/columns/worker-management/workers-list/core/_requests'
const UsersListGrouping = () => {
  const routeParams = useParams()
  
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [mailModalShow, setMailModalShow] = useState(false);
  // Delete Function Start
  const handleJobOfferNotification = ()=>{
    return swal({
      title: "Are you sure?",
      
      icon: "warning",
      buttons:["NO", "Yes"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        OfferSelectedCandidates();
        swal("Your candidates have been sent this offer!", {
          icon: "success",
        });
      } else {
        swal("You cancelled your offer!", {
          icon: "success",
        });
      }
    });
  }
  console.log("🚀 ~ file: UsersListGrouping.tsx:48 ~ UsersListGrouping ~ selected:", selected)

  const sendOfferSelectedCandidates = useMutation(() => getRelatedJobSeekersById(routeParams.project_id!, selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
      
    },
  })
  const OfferSelectedCandidates = async () => {await sendOfferSelectedCandidates.mutateAsync();}
  const handleDelNot = ()=>{
    return swal({
      title: "Are you sure?",
      
      icon: "warning",
      buttons:["NO", "Yes"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        delSelectedUsers();
        swal("Poof! Your candidates have been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your candidates are safe!", {
          icon: "success",
        });
      }
    });
  }
  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
      
    },
  })
  const delSelectedUsers = async () => {await deleteSelectedItems.mutateAsync();}
  
  // Delete Function End
  
  // Save Users Functions Start

  
  
  const saveSelectedUsers = useMutation (()=> saveUser(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
      
    },
  })
  const handleSavedUsers = async() => {
    await saveSelectedUsers.mutateAsync();
  }
  // Save Users Functions End

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      <button
        type='button'
        className='btn btn-primary'
        onClick={ handleSavedUsers}
      >
        Save Workers
      </button>

      {routeParams.project_id !=="undefined" && (
        <button
        type='button'
        className='btn btn-danger ms-2'
        onClick={ handleJobOfferNotification}
      >
        Send Job Offer
      </button>
)}
      
      {/* <button
        type='button'
        className='btn btn-primary ms-2'
        onClick={() => setMessageModalShow(true)}
      >
        Message Worker
      </button> */}
      {/* <button
        type='button'
        className='btn btn-info ms-2'
        onClick={() => setMailModalShow(true)}
      >
        Mail Selected
      </button> */}
      <button
        type='button'
        className='btn btn-primary ms-2'
        // onClick={() => setMailModalShow(true)}
      >
        Download CVs
      </button>
      <button
        type='button'
        className='btn btn-danger ms-2'
        onClick={ handleDelNot}
      >
        Delete
      </button>

      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <MessageModal
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        // user_id = {user?.user_id}
      />
      <MailModal
        show={mailModalShow}
        onHide={() => setMailModalShow(false)}
      />
    </div>
  )
}
function MessageModal(props:any) {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [message, setMessage] = useState('')
  const handleChange = (event:any) => {
    setMessage(event.target.value);
  };
  const notifySelectedItems = useMutation(() => notifySelectedUsers(props.user_id, message), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
    
  })
const handleSubmit =async()=>{
    await notifySelectedItems.mutateAsync()
    swal({
      title: "Message sent successfully.",
      icon: "success",
      dangerMode: false,
    })
}
  // console.log("🚀 ~ file: UsersListGrouping.tsx:67 ~ MyVerticallyCenteredModal ~ message", message)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          MESSAGE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group 
              className="mb-3" 
              controlId="formBasicEmail">
        <Form.Label>Enter Message</Form.Label>
        <Form.Control 
                type="email" 
                placeholder="Enter message" 
                onChange={handleChange}
                as="textarea" aria-label="With textarea" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Button 
            variant="primary" 
            // type="submit"
            onClick={handleSubmit}
            >
            
        Submit
      </Button>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
function MailModal(props:any) {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [message, setMessage] = useState('')
  const handleChange = (event:any) => {
    setMessage(event.target.value);
  };
  const notifySelectedItems = useMutation(() => mailSelectedUsers(selected, message), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
    
  })
const handleSubmit =async()=>{
    await notifySelectedItems.mutateAsync()
    swal({
      title: "Message sent successfully.",
      icon: "success",
      dangerMode: false,
    })
}
  // console.log("🚀 ~ file: UsersListGrouping.tsx:67 ~ MyVerticallyCenteredModal ~ message", message)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group 
              className="mb-3" 
              controlId="formBasicEmail">
        <Form.Label>Enter Mail</Form.Label>
        <Form.Control 
                type="email" 
                placeholder="Enter message" 
                onChange={handleChange}
                as="textarea" aria-label="With textarea" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Button 
            variant="primary" 
            // type="submit"
            onClick={handleSubmit}
            >
            
        Submit
      </Button>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export {UsersListGrouping}
