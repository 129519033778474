import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { MessageModal } from '../../modules/apps/worker-management/workers-list/table/columns/UserActionsCell';
import Pusher from 'pusher-js';
import axios from 'axios';
import defaultAvatar from '../../../_metronic/assets/icons/300-1.jpg'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CLIENTS_URL = `${API_URL}/get-clients`
const ClientPortalHeader = () => {
  const workersBreadcrumbs: Array<PageLink> = [
    {
      title: 'The Staff Hound',
      path: 'clientPortal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const [client, setClient] = useState<any>()
      useEffect(() => {
      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true;

      const pusher = new Pusher('f975851eee4628f8ff0d', {
        cluster: 'us2'
      });

      const channel = pusher.subscribe('my-channel-abc');
      channel.bind('my-event-abc', (data:any) => {
        alert(JSON.stringify(data));
      });

      // cleanup function
      return () => {
        pusher.unsubscribe('my-channel-abc');
        pusher.disconnect();
      };
    }, []);

    useEffect(()=>{
      axios
      .post(GET_CLIENTS_URL, {
        id:sessionStorage.getItem("id")
      })
      .then((d:any)=>{setClient(d.data.success)})
    },[])
    console.log("🚀 ~ file: clientPortalHeader.tsx:57 ~ ClientPortalHeader ~ client:", client)

    
  const [messageModalShow, setMessageModalShow] = useState(false);
  return (
    <>
   <PageTitle breadcrumbs={workersBreadcrumbs}> The Staff Hound</PageTitle>
     <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={defaultAvatar} alt='Metornic' />
              {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>
                    {client?.company_name}
                  </p>
                  <span>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span

                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {client?.business_category}
                  </span>
                  <span
                  
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {client?.company_address}
                  </span>
                  
                </div>
              </div>

              <div className='d-flex my-4'>
                <span className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg'
                    className='svg-icon-3 d-none'
                  />

                  {/* <span className='indicator-label'>Follow</span> */}
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </span>
                {/* <a
                  href='#'
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Hire Me
                </a> */}
                <button
                    type='button'
                    className='btn btn-primary me-2'
                    onClick={() => setMessageModalShow(true)}
                  >
                    Branch Message
                  </button>
                  <MessageModal
                    show={messageModalShow}
                    onHide={() => setMessageModalShow(false)}
                  />
                <div className='me-0'>
                {/* begin::Add user */}
                  <Link to={'/Projects-Posted/Add-Project'}>
                    <button type='button' className='btn btn-primary' >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                      Add Job
                    </button>
                  </Link>
                {/* end::Add user */}
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{client?.total_projects}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Total Jobs</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{client?.open_projects}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Live Jobs</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>200</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Workers Hired</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>160</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>Workers Live</div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>

        
      </div>
    </div>
    </>
   
  )
}

export default ClientPortalHeader