import React, { useState } from 'react'
import {FC} from 'react'
import { Projects } from '../../core/_models'
type Props = {
    project: Projects
  }
const ProjectStatusCell: FC<Props> = ({project}) => {
  const [status, setStatus] = useState<string|undefined>("closed")
  const [background, setBackground] = useState<string|undefined>("danger")



  return (
    <div>
      <div className={`badge badge-light-${background}`}>
      {/* {project?.created_at} */}
        {status}
        
      </div>
    </div>
  )
}

export default ProjectStatusCell
