import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { ProjectsPostedListWrapper } from './posted-projects-list/UsersList'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Job Rack',
    path: '/apps/projects-posted/projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectsPostedPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='projects'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Posted Projects </PageTitle>
              <ProjectsPostedListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/projects-posted/' />} />
    </Routes>
  )
}

export default ProjectsPostedPage
