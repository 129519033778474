import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import { UsersListFilter } from './UsersListFilter'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

const   UsersListHeader = () => {
  const {selected} = useListView()
  return (
  <>
    <div className='card-header border-bottom-5  py-6 mb-2 '><UsersListFilter /></div>
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  </>
    
  )
}

export {UsersListHeader}
