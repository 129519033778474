import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProjectsPostedListHeader} from './components/header/UsersListHeader'

import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

// import { useState } from 'react'
import { ProjectsPostedTable } from './table/UsersTable'

const ProjectsPostedList = () => {
  const {itemIdForUpdate} = useListView()

  // const [key, setKey] = useState<string>('listed');
  return (
    <>
    <KTCard>
      <ProjectsPostedListHeader />
        
        <ProjectsPostedTable />

      
        
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const   ProjectsPostedListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProjectsPostedList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProjectsPostedListWrapper}
