import axios, { AxiosResponse } from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { KTCard, Response } from '../../../../../_metronic/helpers'
import { Client } from '../../clients-management/clients-list/core/_models'
import { addProject } from '../users-list/core/_requests'
import swal from 'sweetalert'
const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CLIENT_URL = `${API_URL}/get-clients`
const GET_CATEGORIES_URL = `${API_URL}/get-categories`
const AddProjectClient:FC = () => {
    
      const [client, setClient] = useState<any>()
      
      const [categories, setCategories] = useState<any>()
      const [value, setValue] = useState<any>()



    const client_id = client?.id
    console.log("🚀 ~ file: AddProjectClient.tsx:20 ~ client?.id:", client)
    const [category_id, setCategoryId] = useState<string | undefined>();
    const [title, setTitle] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [rate_hour, setRateHour] = useState<string | undefined>();
    const [required, setRequired] = useState<string | undefined>();    
    const [required_at, setRequiredAt] = useState<string | undefined>();
    const [responsibilities, setResponsibilities] = useState<string | undefined>();
    const [working_hours, setWorkingHours] = useState<string | undefined>();
    const [hiring_type, setHiringType] = useState<string | undefined>();
    
          useEffect(() => {
      const fetchData = async () => {
        const [clientResponse, categoriesResponse] = await Promise.all([
          axios.post(GET_CLIENT_URL, {
            id:sessionStorage.getItem("id")
          }),
          axios.post(GET_CATEGORIES_URL)
        ]);
        setClient(clientResponse.data?.success);
        setCategories(categoriesResponse.data?.success);
        console.log("🚀 ~ file: AddProjectClient.tsx:42 ~ fetchData ~ clientResponse.data?.success:", clientResponse.data?.success)
      };
      fetchData();
    }, []);
      const today = new Date();
      const dateToday =today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();

      ////////SESSION::VALUES::CLIENT////////
      sessionStorage.getItem("name");
      sessionStorage.getItem("type");


      const project = {
               
                category_id: category_id,
                title: title,
                description: description,
                rate_hour: rate_hour,
                branch: "Columbia",
                required: required,
                selected: 0,
                required_at: required_at,
                responsibilities: responsibilities,
                working_hours: working_hours,
                hiring_type: hiring_type,
                status: null,
      }
                console.log("🚀 ~ file: AddProjectClient.tsx:68 ~ client_id:", client_id)
        
      const handleSubmit = ()=>{
        swal({
            title: "Are you sure?",
            text: "Once submitted, you will not be able to modify the project!",
            icon: "warning",
            buttons:["NO", "Yes"],
            dangerMode: true,
        })
        .then((willSubmit:any) => {
            if (willSubmit) {
                addProject(project);
                swal("Your project has been sent for approval!", {
                  icon: "success",
                })
            }
            else {
              swal("Cancelled!", {
                icon: "error",
              });
            }
        });
      }  
      
      const joined_at = new Date(client?.created_at)? new Date(client?.created_at): new Date();
        console.log(client)
        console.log(categories?.success)
    
      
      
  return (
    <>
    
        <form  className=''>
          <KTCard className='mb-3 p-5'>
              <h3 className='form-label fs-16 fw-bold'>01. General</h3>
                  {/* First ROW */}
                  <div className="row">

                      <div className="col-md-4">
                        <label className='form-label fs-6 fw-bold required'>Client:</label>
                        <input 
                            type="text" 
                            className="form-control form-control-solid" 
                            name="name" 
                            value={client?.name} 
                            id="name" 
                            readOnly/>
                          
                        
                      </div>
                      <div className="col-md-4">
                        <label className='form-label fs-6 fw-bold required'>Location:</label>
                        <input 
                            type="text" 
                            className="form-control form-control-solid" 
                            placeholder="" 
                            onChange={(e)=>{}} 
                            defaultValue={client?.company_address} 
                            name="location"  
                            required
                            readOnly/>
                      </div>
                      <div className="col-md-4">
                        <label className='form-label fs-6 fw-bold required'>Date Recieved:</label>
                        <input 
                            type="text" 
                            className="form-control form-control-solid" 
                            placeholder="" 
                            onChange={(e)=>{}} 
                            value={dateToday} 
                            name="date_recieved" 
                            readOnly 
                            required/>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-4">
                        <label className='form-label fs-6 fw-bold required'>Job title:</label>
                        <input 
                            type="text" 
                            className="form-control form-control-solid"  
                            name="job_title" 
                            onChange={(e)=>{setTitle(e.target.value)}} 
                            placeholder="Enter Job Title"  
                            required/>
                      </div>
                      <div className="col-md-4">
                        <label className='form-label fs-6 fw-bold required'>Salary:</label>
                        <input 
                            type="number" 
                            className="form-control form-control-solid" 
                            name="salary"  
                            placeholder="Enter Salary" 
                            onChange={(e)=>{setRateHour(e.target.value)}} 
                            required/>
                      </div>
                      <div className="col-md-4">
                      <label className='form-label fs-6 fw-bold required'>Hiring Type:</label>
                          <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='two-step'
                          data-hide-search='true'
                          onChange={(e)=>{setHiringType(e.target.value)}} 
                          required
                          // onChange={(e) => setLastLogin(e.target.value)}
                          // value={lastLogin}
                          >
                          <option value=''>Select</option>
                          <option value='Opened'>Temp</option>
                          <option value='Closed'>Hire</option>
                          <option value='Successful'>Temp to Hire</option>
                          <option value='Closed'>Contract</option>
                          </select>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className='form-label fs-6 fw-bold'>No. of candidates required:</label>
                      <input 
                          type="number" 
                          className="form-control form-control-solid required" 
                          placeholder="Enter Required Candidates"  
                          name="required_candidates" 
                          onChange={(e)=>{setRequired(e.target.value)}} 
                          required />
                    </div>
                    <div className="col-md-4">
                      <label className='form-label fs-6 fw-bold'>Required by(date):</label>
                      <input 
                          type="date" 
                          className="form-control form-control-solid required" 
                          placeholder="Enter Date"  
                          name="requiredDate"  
                          onChange={(e)=>{setRequiredAt(e.target.value)}} 
                          required/>
                    </div>
                  <div className="col-md-4">
                      <label className='form-label fs-6 fw-bold required'>Category:</label>
                      <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='two-step'
                          data-hide-search='true'
                          required
                          onChange={(e) =>{setCategoryId(e.target.value)}}
                        // value={lastLogin}
                        >
                        <option value=''>Select</option>
                        {categories && categories.length > 0 ? categories.map((category: any, key: any)=>{
                              return(
                                <option value={category.id} key={key}>{category.title}</option>
                              )
                          }) : null}
                      </select>
                      </div>
                  </div>
            
          </KTCard>
          <KTCard className='mb-3 p-5'>
            
              <h3 className='form-label fs-16 fw-bold'>02. Details</h3>
                  {/* First ROW */}
                  <div className="row">
                    <div className="col-md-12"> 
                      <label className='form-label fs-6 fw-bold'>Job Description:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Enter Job Description'
                            name="job_description" 
                            id="job_description" 
                            onChange={(e)=>{setDescription(e.target.value)}}
                            cols={3} 
                            rows={10}
                            required
                            >
                          
                        </textarea>
                    </div>
                    <div className="col-md-12"> 
                      <label className='form-label fs-6 fw-bold required'>Responsibilities:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Enter Responsibilities'
                            name="responsibilitites" 
                            id="responsibilitites" 
                            onChange={(e)=>{setResponsibilities(e.target.value)}}
                            cols={3} 
                            rows={10}
                            required
                            >

                        </textarea>
                    </div>
                    <div className="col-md-12">
                    <label className='form-label fs-6 fw-bold required'>Working Hours:</label>
                        <textarea 
                            className='form-control form-control-solid mb-3'
                            placeholder='Enter Working Hours'
                            name="working_hours" 
                            id="working_hours" 
                            onChange={(e)=>{setWorkingHours(e.target.value)}}
                            cols={3} 
                            rows={5}
                            required
                            >
                          
                        </textarea>
                    </div>


                  </div>
          
          </KTCard>
          <KTCard className='mb-3 p-5'>
            
              <h3 className='form-label fs-16 fw-bold'>03. Contact</h3>
              <div className="row">
                <div className="col-md-6">
                  <label className='form-label fs-6 fw-bold'>Report to:</label>
                  <input 
                      type="text" 
                      className="form-control form-control-solid" 
                      placeholder="Enter here" 
                      value={client?.contact_person}
                      name="report_to"  
                      readOnly/>
                </div>
                <div className="col-md-6">
                  <label className='form-label fs-6 fw-bold'>Contact no:</label>
                  <input 
                      type="text" 
                      className="form-control form-control-solid" 
                      placeholder="Enter contact no" 
                      value={client?.phone}
                      name="contact_no" 
                      readOnly />
                </div>
                <div className="col-md-6">
                  <label className='form-label fs-6 fw-bold'>Company Address:</label>
                  <input 
                      type="text" 
                      className="form-control form-control-solid" 
                      placeholder="Enter company address" 
                      name="company_address" 
                      value={client?.company_address}
                      readOnly />
                </div>
                <div className="col-md-6">
                  <label className='form-label fs-6 fw-bold'>Joining Date:</label>
                  <input 
                      type="text" 
                      className="form-control form-control-solid" 
                      placeholder="Enter {}" 
                      value={joined_at.toLocaleDateString()}
                      name="joining_date"  
                      readOnly/>
                </div>
            
              </div>
              <button 
                  className='btn btn-primary btn-block w-25  ms-auto mt-2'
                  type='button'
                  onClick={handleSubmit}
                  >
                  Post
              </button>
          </KTCard>
        </form>
  
    
    </>
  )
}

export default AddProjectClient