import React, { useEffect, useState } from 'react'
import {CV, User} from '../../core/_models'
import {FC} from 'react'
// import jsPDF from 'jspdf';
// import { getUserCV } from '../../core/_requests';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import './CV.css'
import axios, { AxiosResponse } from 'axios';
import {  Response } from '../../../../../../../_metronic/helpers';



type user = {
    user:User
}
const API_URL = process.env.REACT_APP_THEME_API_URL
const CV_URL = `${API_URL}/get-cv`
const UserCVCell: FC<user> = ({user}) =>{
    const [modalShow, setModalShow] = useState(false);
    const [response1, setResponse] = useState(Object)
    // const cvHandle=() => { 
    //     const promise = getUserCV(user.id);
    //     const response = promise.then(response=> {
    //         setResponse(response?.data)
    //     })
    //     // console.log(`This is the global response for user ${user.id}`, response1[0].id)
    //     setModalShow(true)
    //     return response
    // }
    // if(response1>0){
    // console.log(response1[0].id)
    // }
    // console.log(user)
    $(".js-example-responsive").select2({
        width: '200px',
        placeholder: 'Select an option',
        
         // need to override the changed default
    });
    const [skills, setSkills] = useState<any>()
    const [languages, setLanguages] = useState<any>()
    useEffect(() => {
        if(user.cv?.skills !== undefined){
            let skill = (user.cv?.skills).replace("[", "").replace("]", "").split(",")
            let language = (user.cv.languages!).replace("[", "").replace("]", "").split(",")
            setSkills(skill)
            setLanguages(language)
            
    }
    
    
    }, [])
    
    const cvHandle = () => {
        setModalShow(true)
        
        return axios
            .post(CV_URL, {
                id:user?.user_id,
            })
            .then((response: AxiosResponse<Response<CV>>) => response.data)
            .then((response: Response<CV>) => setResponse(response.data)) 
        
        }
        const Print = () =>{     
            //console.log('print');  
            let printContents = document.getElementById('printablediv')!.innerHTML;
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents; 
            setTimeout(function() {
                window.location.reload();
            }, 1000);
            }
    const education = response1[0]?.education
    const experience = response1[0]?.experience
    const cV = response1[0]
    return (
        <div className='d-flex d-flex align-items-center'>

            <Button variant="" className={"cvBtn mx-3 "}onClick={cvHandle}>
            <span className="fa-item col-md-3 col-sm-4">
                <i className="fa cvBtn fa-eye "></i>
            </span>            
            </Button>
                
            <Button variant="" className={"cvBtn"}onClick={Print}>
                <span className="fa-item  d-flex justify-content-center align-items-center col-md-3 col-sm-4">
                    <i className="fa-solid cvBtn fa-download"></i>
                </span>
            </Button>
            {/* <select className="js-example-responsive " 
                    >
                <option value="AL">Alabama1</option>
                <option value="AsL">Alabama2</option>
                <option value="AdL">Alabama3</option>
                    <option value="AfL">Alabama4</option>
        </select> */}
        
        

            <Modal  
                
                
                show={modalShow}
                aria-labelledby="contained-modal-title-vcenter" 
                dialogClassName="customModal"
                // className="customModal"
                size="lg"
                id="printablediv"
                >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Resume
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
            {/* Main Row */}
                <Row>
                <Col lg={12} xs={12} md={12}>
                    <h1 
                        style={{ 
                            fontSize: '34px',
                            marginTop:'10px' 
                        }}
                        className="">
                            {user?.name}
                    </h1>
                    <h2 
                        style={{ 
                            color:"grey",
                            paddingBottom:"50px",
                            }}>
                        {(education?.length > 0)?experience[0].designation:null}
                    </h2>
                    
                </Col>
                </Row>
                <Row>
                <Col xs={6} md={4}>
                <h1 className='text-primary'>Contact</h1>
                    <p><i className="fa fa-phone" /> {user.phone}</p>
                    <p><i className="fa fa-envelope"></i> {user.email}</p>
                    <p><i className="fa fa-location-pin"></i> {user.city}, {user.country}</p>
                <h1 className='text-primary marginTop'>Skills</h1>
                <ul>
                    {(skills?.length > 0 )?skills.map((item:any, key:any)=>{
                        return(
                        <li key={key}>{item}</li>
                        )
                    }):"nothing"}
                    
                </ul>
                <h1  className='text-primary marginTop'>Languages</h1>
                <ul>
                {(languages?.length > 0 )?languages.map((language:any, key:any)=>{
                        return(
                        <li key={key}>{language}</li>
                        )
                    }):"nothing"}
                </ul>
                </Col>
                <Col className='borderLeft' xs={6} md={8}>
                    <h1 className='text-primary'>Summary</h1>
                    <p>{response1[0]?.about_me}</p>
                    
                    {/* Education Section */}
                    
                    <h1 className='text-primary marginTop'>Education</h1>
                    
                    

                    {
                    (education?.length > 0)?education.map((item:any, key:any)=>{
                    return <Row className="mb-5"key ={key}>
                    <Col  xs={6} md={8}>
                    <h4>{education[key]?.institute}</h4> 
                    <p>{education[key]?.qualification}</p>
                    </Col>
                    <Col className='text-right'xs={6} md={4}>
                    <h4>{education[key]?.start_date? education[key]?.start_date.slice(0, 4):"Start Date not provided"} - {education[key]?.end_date ?education[key]?.end_date.slice(0, 4):"End Date not provided"}</h4>
                    </Col>
                    </Row>
                }):"nothing"}

                    {/* Experience Section */}
                    <h1 className='text-primary'>Experience</h1>
                    
                    {
                    (experience?.length > 0)?experience.map((item:any, key:any)=>{
                    return <Row className="mb-5" key ={key}>
                    <Col xs={6} md={8}>
                    <h4>{experience[key]?.company}</h4> 
                    <p>{experience[key]?.designation}</p>
                    </Col>
                    <Col className='text-right'xs={6} md={4}>
                    <h4>{education[key]?.start_date? experience[key]?.start_date.slice(0, 4):"Start Date not provided"} - {education[key]?.end_date ? education[key]?.end_date.slice(0, 4):"End Date not provided"}</h4>
                    </Col>
                    </Row>
                }):"nothing"}
                </Col>

                </Row>
            {/* </Container> */}
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={()=>{setModalShow(false)
            console.log("test")}}>Close</Button>
            <div>
            <Button
                onClick={Print}
                >
                Download PDF
            </Button>
    
    </div>
            </Modal.Footer>
        </Modal>
        </div>
    )
}
    




export default UserCVCell