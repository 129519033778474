/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import swal from 'sweetalert'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, QUERIES } from '../../../../../../../_metronic/helpers'

import { useQueryResponse } from '../../core/QueryResponseProvider'




import { User } from '../../core/_models'
import {approveJobseeker} from '../../core/_requests'
type Props = {
  id: ID,
  user:User
}

const AppliedWorkersActionsCell: FC<Props> = ({id, user}) => {




  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  
const userId = user?.applied_id;


  console.log("🚀 ~ file: AppliedWorkersActionsCell.tsx:33 ~user:", user?.applied_status)
  const [status, setStatus] = useState<string | undefined>("approved")
  const handleApprove =async()=>{
    setStatus("approved")
    await approveSelectedJobseekers.mutateAsync()
    swal({
      title: "Approved Jobseeker successfully.",
      icon: "success",
    })
}
const handleDecline =async()=>{
  setStatus("declined")
  await approveSelectedJobseekers.mutateAsync()
  swal({
    title: "Declined Jobseeker successfully.",
    icon: "success",
  })
}
  const approveSelectedJobseekers = useMutation(() => approveJobseeker(userId, status), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      
    },
    
  })
  return (
    <div className="d-flex justify-content-center">
    
      
      {user?.applied_status !== "approved"?<>
      
          <Button 
              variant="primary" 
              className={"cvBtn me-2 text-center"}
              onClick={handleApprove}>
                    <i className="fa-sharp ms-4 fa-regular fa-circle-check"></i>  
          </Button>


          <Button 
              variant="danger" 
              className={"cvBtn"}
              onClick={handleDecline}>
              

                  <i className="fa-regular ms-4 fa-circle-xmark"></i>       
                
                
          </Button>
      </>:<span className='badge  justify-content-center badge-light-warning'>
          on waiting
      </span>
    }
      
      
      
    </div>
  )
}

export {AppliedWorkersActionsCell}
