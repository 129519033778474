import React from 'react'
import {User} from '../../core/_models'
import {FC} from 'react'
type Props = {
    user: User
}
const SelectedWorkersLocationCell: FC<Props> = ({user}) =>{
    

    
    return(
        <div>{user.city}, {user?.country}</div>
    )
}

export  {SelectedWorkersLocationCell}