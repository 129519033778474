import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {SelectedWorkersTable} from './table/SelectedWorkersTable'


import { SelectedWorkersEditModal } from './user-edit-modal/SelectedWorkersEditModal'
import { KTCard } from '../../../../../_metronic/helpers'


const SelectedWorkersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <SelectedWorkersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SelectedWorkersEditModal />}
    </>
  )
}

const SelectedWorkersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SelectedWorkersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default SelectedWorkersListWrapper
