import {Column} from 'react-table'
import {ProjectInfoCell} from './ProjectInfoCell'


import {ProjectSelectionCell} from './ProjectSelectionCell'
import {ProjectCustomHeader} from './ProjectCustomHeader'
import {ProjectSelectionHeader} from './ProjectSelectionHeader'
import {User} from '../../core/_models'
import ProjectBranchCell from './ProjectBranchCell'
import ProjectReqCandCell from './ProjectReqCandCell'
import ProjectSelCandCell from './ProjectSelCandCell'
import ProjectPenCandCell from './ProjectPenCandCell'

import ProjectClientCell from './ProjectClientCell'
import ProjectStatusCell from './ProjectStatusCell'

const ProjectColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <ProjectSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProjectSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Job Id' className='min-w-50px' />,
    id: 'client_id',
    Cell: ({...props}) => <ProjectClientCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <ProjectInfoCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Agency/Branch' className='min-w-125px' />,
    id: 'location',
    Cell: ({...props}) => <ProjectBranchCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Status' className='min-w-100px' />,
    id: 'status',
    Cell: ({...props}) => <ProjectStatusCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Required Workers' className='min-w-100px' />,
    id: 'role',
    Cell: ({...props}) => <ProjectReqCandCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Selected Workers' className='min-w-100px' />
    ),
    id: 'last_login',
    Cell: ({...props}) => <ProjectSelCandCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Pending Workers' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ProjectPenCandCell project={props.data[props.row.index]} />,
  },
 
  // {
  //   Header: (props) => (
  //     <ProjectCustomHeader tableProps={props} title='Deadline' className='min-w-125px' />
  //   ),
  //   id: 'deadline',
  //   Cell: ({...props}) => <ProjectDateCell project={props.data[props.row.index]} />,
  // },

  // {
  //   Header: (props) => (
  //     <ProjectCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <ProjectActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {ProjectColumns}
