import React from 'react'
import {FC} from 'react'
import { Projects } from '../../core/_models'
type Props = {
    project: Projects
  }
const ProjectDateCell: FC<Props> = ({project}) => {
  return (
    <div className='badge badge-light-danger'>
      {/* {project?.created_at} */}
        Close
    </div>
  )
}

export default ProjectDateCell
