import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { MessageModal } from '../../../../worker-management/workers-list/table/columns/UserActionsCell';

import { getClient } from '../../core/_requests';


import { UsersListWrapper } from './Project-management/projects-list/UsersList'






const ClientDetailsPage = () => {
    const {id} = useParams()
    const [client, setClient] = useState<any>([]);
    const lastLogin = new Date(client?.oauth_token_datetime?.updated_at);
    const [messageModalShow, setMessageModalShow] = useState(false);

    useEffect(() => {
    getClient(id!).then((response) => {
        setClient(response?.success!)
    })},[id]
    )

    

    return (
    <><div className="content d-flex flex-column flex-column-fluid" id="kt_content">
    {/*begin::Container*/}
    <div className="container-xxl" id="kt_content_container">
        {/*begin::Layout*/}
        <div className="d-flex flex-column flex-lg-row">
            {/*begin::Sidebar*/}
            <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
                {/*begin::Card*/}
                <div className="card mb-5 mb-xl-8">
                    {/*begin::Card body*/}
                    <div className="card-body">
                        {/*begin::Summary*/}
                        {/*begin::User Info*/}
                        <div className="d-flex flex-center flex-column py-5">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-100px symbol-circle mb-7">
                                <img src="https://i.pinimg.com/564x/4f/f6/6d/4ff66d0783159436583bd3be5eaf91e2.jpg" alt="aimage" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Name*/}
                            <p className="fs-3 text-gray-800 fw-bold mb-3">{client?.company_name?client?.company:"Fair Play Services"}</p>
                            {/*end::Name*/}
                            {/*begin::Position*/}
                            <div className="mb-9">
                                {/*begin::Badge*/}
                                <div className="badge badge-lg badge-light-primary d-inline">Client of Agency/Branch</div>
                                {/*begin::Badge*/}
                            </div>
                            {/*end::Position*/}
                            {/*begin::Info*/}
                            {/*begin::Info heading*/}
                            <div className="fw-bold mb-3">
                                Jobs
                            </div>
                            {/*end::Info heading*/}
                            <div className="d-flex flex-wrap flex-center">
                                {/*begin::Stats*/}
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700">
                                        <span className="w-50px">{client?.open_projects}</span>
                                    </div>
                                    <div className="fw-semibold text-muted">Open </div>
                                </div>
                                {/*end::Stats*/}
                                {/*begin::Stats*/}
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-50px">{client?.closed_projects}</span>
                                        
                                    </div>
                                    <div className="fw-semibold text-muted">Closed </div>
                                </div>
                                {/*end::Stats*/}
                                {/*begin::Stats*/}
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700">
                                        <span className="w-75px">{client?.total_projects}</span>
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                        {/*end::Svg Icon*/}
                                    </div>
                                    <div className="fw-semibold text-muted">Total </div>
                                </div>
                                {/*end::Stats*/}
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::User Info*/}
                        {/*end::Summary*/}
                        {/*begin::Details toggle*/}
                        <div className="d-flex flex-stack fs-4 py-3">
                            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Details
                            </div>
                            
                        </div>
                        {/*end::Details toggle*/}
                        <div className="separator"></div>
                        {/*begin::Details content*/}
                        <div id="kt_user_view_details" className="collapse show">
                            <div className="pb-5 fs-6">
                                {/*begin::Details item*/}
                                <div className="fw-bold mt-5">Email</div>
                                <div className="text-gray-600">
                                    <a href="!#" className="text-gray-600 text-hover-primary">{client?.email}</a>
                                </div>
                                {/*begin::Details item*/}
                                {/*begin::Details item*/}
                                <div className="fw-bold mt-5">
                                    Contact
                                </div>
                                <div className="text-gray-600">
                                    {client?.phone}
                                </div>
                                {/*begin::Details item*/}
                                {/*begin::Details item*/}
                                <div className="fw-bold mt-5">
                                    Location
                                </div>
                                <div className="text-gray-600">
                                    {client?.city}, {client?.country}
                                </div>
                                {/*begin::Details item*/}
                                <div className="fw-bold mt-5">
                                    Last Login
                                </div>
                                <div className="text-gray-600">
                                    {lastLogin.toLocaleString()}

                                </div>
                                {/*begin::Details item*/}
                                
                                
                                
                            </div>
                        </div>
                        {/*end::Details content*/}
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Card*/}
            
            </div>
            {/*end::Sidebar*/}
            {/*begin::Content*/}
            <div className="flex-lg-row-fluid ms-lg-15">
                {/*begin:::Tabs*/}
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                    {/*begin:::Tab item*/}
                    {/* <li className="nav-item">
                        <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_user_view_overview_tab">Jobs</a>
                    </li> */}
                    {/*end:::Tab item*/}
                    
                    <li className="nav-item ms-auto">

                    {/* <button 
                        type="button"
                        className='btn btn-primary me-2'
                        onClick={() => setMessageModalShow(true)}
                        >
                        Message
                    </button> */}
                    
                    </li>
                
                </ul>
                <MessageModal   
                        show={messageModalShow}
                        onHide={() => setMessageModalShow(false)}
                        user_id={client?.oauth_token_datetime?.user_id!}
                    />
                {/*end:::Tabs*/}
                {/*begin:::Tab content*/}
                <div className="tab-content" id="myTabContent">
                    {/*begin:::Tab pane*/}
                    <div className="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
                        {/*begin::Card*/}
                        <UsersListWrapper/>
                        {/*end::Card*/}
                        

                    </div>
                    {/*end:::Tab pane*/}
                    {/*begin:::Tab pane*/}
                    <div className="tab-pane fade" id="kt_user_view_overview_security" role="tabpanel">
                        <h3>tab 2 </h3>
                    </div>
                    {/*end:::Tab pane*/}
                    {/*begin:::Tab pane*/}
                    <div className="tab-pane fade" id="kt_user_view_overview_events_and_logs_tab" role="tabpanel">
                        <h3>tab 3</h3>
                    </div>
                    {/*end:::Tab pane*/}
                </div>
                {/*end:::Tab content*/}
            </div>
            {/*end::Content*/}
        </div>
        {/*end::Layout*/}
        {/*begin::Modals*/}
        {/*begin::Modal - Update user details*/}
        <div className="modal fade" id="kt_modal_update_details" tabIndex={-1} aria-hidden="false">
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
                {/*begin::Modal content*/}
                <div className="modal-content">
                    {/*begin::Form*/}
                    <form className="form" action="#" id="kt_modal_update_user_form">
                        {/*begin::Modal header*/}
                        <div className="modal-header" id="kt_modal_update_user_header">
                            {/*begin::Modal title*/}
                            <h2 className="fw-bold">Update User Details</h2>
                            {/*end::Modal title*/}
                            {/*begin::Close*/}
                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                <span className="svg-icon svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className="modal-body py-10 px-lg-17">
                            {/*begin::Scroll*/}
                            <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_user_header" data-kt-scroll-wrappers="#kt_modal_update_user_scroll" data-kt-scroll-offset="300px">
                                {/*begin::User toggle*/}
                                <div className="fw-bolder fs-3 rotate collapsible mb-7" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="kt_modal_update_user_user_info">User Information
                                <span className="ms-2 rotate-180">
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </span></div>
                                {/*end::User toggle*/}
                                {/*begin::User form*/}
                                <div id="kt_modal_update_user_user_info" className="collapse show">
                                    {/*begin::Input group*/}
                                    <div className="mb-7">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span>Update Avatar</span>
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Allowed file types: png, jpg, jpeg."></i>
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Image input wrapper*/}
                                        <div className="mt-1">
                                            {/*begin::Image placeholder*/}
                                            
                                            {/*end::Image placeholder*/}
                                            {/*begin::Image input*/}
                                            <div className="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                                                {/*begin::Preview existing avatar*/}
                                                <div className="image-input-wrapper w-125px h-125px" style={{backgroundImage: "url(assets/media/avatars/300-6.jpg)"}}></div>
                                                {/*end::Preview existing avatar*/}
                                                {/*begin::Edit*/}
                                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                    <i className="bi bi-pencil-fill fs-7"></i>
                                                    {/*begin::Inputs*/}
                                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                    <input type="hidden" name="avatar_remove" />
                                                    {/*end::Inputs*/}
                                                </label>
                                                {/*end::Edit*/}
                                                {/*begin::Cancel*/}
                                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                    <i className="bi bi-x fs-2"></i>
                                                </span>
                                                {/*end::Cancel*/}
                                                {/*begin::Remove*/}
                                                <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                    <i className="bi bi-x fs-2"></i>
                                                </span>
                                                {/*end::Remove*/}
                                            </div>
                                            {/*end::Image input*/}
                                        </div>
                                        {/*end::Image input wrapper*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-7">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Name</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input type="text" className="form-control form-control-solid" placeholder="" name="name"  />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-7">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span>Email</span>
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Email address must be active"></i>
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input type="email" className="form-control form-control-solid" placeholder="" name="email"  />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-7">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Description</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input type="text" className="form-control form-control-solid" placeholder="" name="description" />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-15">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Language</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select name="language" aria-label="Select a Language" data-control="select2" data-placeholder="Select a Language..." className="form-select form-select-solid" data-dropdown-parent="#kt_modal_update_details">
                                            <option></option>
                                            <option value="id">Bahasa Indonesia - Indonesian</option>
                                        
                                        </select>
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                </div>
                                {/*end::User form*/}
                                {/*begin::Address toggle*/}
                                <div className="fw-bolder fs-3 rotate collapsible mb-7" data-bs-toggle="collapse"role="button" aria-expanded="false" aria-controls="kt_modal_update_user_address">Address Details
                                <span className="ms-2 rotate-180">
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </span></div>
                                {/*end::Address toggle*/}
                                {/*begin::Address form*/}
                                <div id="kt_modal_update_user_address" className="collapse show">
                                    {/*begin::Input group*/}
                                    <div className="d-flex flex-column mb-7 fv-row">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Address Line 1</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control form-control-solid" placeholder="" name="address1" />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="d-flex flex-column mb-7 fv-row">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Address Line 2</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control form-control-solid" placeholder="" name="address2" />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="d-flex flex-column mb-7 fv-row">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">Town</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input className="form-control form-control-solid" placeholder="" name="city"  />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row g-9 mb-7">
                                        {/*begin::Col*/}
                                        <div className="col-md-6 fv-row">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-semibold mb-2">State / Province</label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input className="form-control form-control-solid" placeholder="" name="state"  />
                                            {/*end::Input*/}
                                        </div>
                                        {/*end::Col*/}
                                        {/*begin::Col*/}
                                        <div className="col-md-6 fv-row">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-semibold mb-2">Post Code</label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input className="form-control form-control-solid" placeholder="" name="postcode" />
                                            {/*end::Input*/}
                                        </div>
                                        {/*end::Col*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="d-flex flex-column mb-7 fv-row">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span>Country</span>
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Country of origination"></i>
                                        </label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select name="country" aria-label="Select a Country" data-control="select2" data-placeholder="Select a Country..." className="form-select form-select-solid" data-dropdown-parent="#kt_modal_update_details">
                                            <option value="">Select a Country...</option>
                            
                                        
                                        </select>
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                </div>
                                {/*end::Address form*/}
                            </div>
                            {/*end::Scroll*/}
                        </div>
                        {/*end::Modal body*/}
                        {/*begin::Modal footer*/}
                        <div className="modal-footer flex-center">
                            {/*begin::Button*/}
                            <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                            {/*end::Button*/}
                            {/*begin::Button*/}
                            <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                            {/*end::Button*/}
                        </div>
                        {/*end::Modal footer*/}
                    </form>
                    {/*end::Form*/}
                </div>
            </div>
        </div>
        {/*end::Modal - Update user details*/}
        {/*begin::Modal - Add schedule*/}
        <div className="modal fade" id="kt_modal_add_schedule" tabIndex={-1} aria-hidden="true">
            {/*begin::Modal dialog*/}
            <div className="modal-dialog modal-dialog-centered mw-650px">
                {/*begin::Modal content*/}
                <div className="modal-content">
                    {/*begin::Modal header*/}
                    <div className="modal-header">
                        {/*begin::Modal title*/}
                        <h2 className="fw-bold">Add an Event</h2>
                        {/*end::Modal title*/}
                        {/*begin::Close*/}
                        <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                            <span className="svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </div>
                        {/*end::Close*/}
                    </div>      
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        {/*begin::Form*/}
                        <form id="kt_modal_add_schedule_form" className="form" action="#">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7">
                                {/*begin::Label*/}
                                <label className="required fs-6 fw-semibold form-label mb-2">Event Name</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="text" className="form-control form-control-solid" name="event_name"  />
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-semibold form-label mb-2">
                                    <span className="required">Date & Time</span>
                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Select a date & time."></i>
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input className="form-control form-control-solid" placeholder="Pick date & time" name="event_datetime" id="kt_modal_add_schedule_datepicker" />
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7">
                                {/*begin::Label*/}
                                <label className="required fs-6 fw-semibold form-label mb-2">Event Organiser</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="text" className="form-control form-control-solid" name="event_org"  />
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7">
                                {/*begin::Label*/}
                                <label className="required fs-6 fw-semibold form-label mb-2">Send Event Details To</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input id="kt_modal_add_schedule_tagify" type="text" className="form-control form-control-solid" name="event_invitees" />
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Actions*/}
                            <div className="text-center pt-15">
                                <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                                <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            {/*end::Actions*/}
                        </form>
                        {/*end::Form*/}
                    </div>
                    {/*end::Modal body*/}
                </div>
                {/*end::Modal content*/}
            </div>
            {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - Add schedule*/}
        
        {/*end::Modals*/}
    </div>
    {/*end::Container*/}
</div>
</>
  )
}

export default ClientDetailsPage