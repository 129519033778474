import React, { useEffect, useState } from 'react'
import {FC} from 'react'
import { Dropdown } from 'react-bootstrap'
import { Projects } from '../../../../../../../../projects-management/users-list/core/_models'
import { updateStatus } from '../../core/_requests'

type Props = {
    project: Projects
  }
const ProjectStatusCell: FC<Props> = ({project}) => {
  const [status, setStatus] = useState<string|null>("close")
  const [background, setBackground] = useState<string|undefined>("")


  useEffect(() => {
    
    if (project?.status === "open") // Approved
    {
      setStatus("open") 
      setBackground("success")
    }else if (project?.status === "closed")// Unapproved
    {
      setStatus("closed")
      setBackground("danger")
    }
  }
  , [project])
  
  
  return (
    <>
      <Dropdown>
      <Dropdown.Toggle variant='' id="dropdown-basic"className={`badge badge-light-${background}`}>
        {status}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item 
            className={`badge justify-content-center badge-light-success`} 
            data-kt-menu='true'
            onClick={()=>{
            updateStatus(project?.id, "open")
            setStatus("Open")
            setBackground("success") 
            }} >
              Open
      </Dropdown.Item>
      <Dropdown.Item 
            className={`badge justify-content-center badge-light-danger`}
            data-kt-menu='true'
            onClick={()=>{
            updateStatus(project?.id, "closed")
            setStatus("Closed")
            setBackground("danger") 
            }} >
              Close
      </Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
    </>
  )
}

export default ProjectStatusCell
