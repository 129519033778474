import React from 'react'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import ApproveProjectPage from './ApproveProjectPage'

const ApproveProjectWrapper = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
        title: 'Approve Projects',
        path: '/apps/projects-management/approve-projects',
        isSeparator: false,
        isActive: false,
        },
        
    ]
return (
    <>
        <PageTitle breadcrumbs={usersBreadcrumbs}>Approve Job</PageTitle>
        <ApproveProjectPage/>
    </>
    )
}

export default ApproveProjectWrapper