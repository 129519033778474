import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

import {useQueryResponseData} from '../../core/QueryResponseProvider'
import * as XLSX from 'xlsx'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddClientModal = () => {
    setItemIdForUpdate(null)
  }
  const users = useQueryResponseData()
  
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "MyExcel.xlsx")
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      <Link to='Client-Management/Add-Client'></Link>
      <button type='button' className='btn btn-primary me-3' onClick={openAddClientModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Client
      </button>
      {/* end::Add user */}
      

      {/* begin::Export */}
      <button 
              type='button' 
              className='btn btn-light-primary me-3'
              onClick={handleOnExport}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      
    </div>
  )
}

export {UsersListToolbar}


const AddClientModal = () => {
  return (
    <> <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Modal heading
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-md-12"></div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button >Close</Button>
    </Modal.Footer>
  </Modal></>
  )
}

export default AddClientModal