import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UsersListWrapper} from './workers-list/UsersList'
import { Tab, Tabs } from 'react-bootstrap'
import { useState } from 'react'

import { WorkersRequestListWrapper } from '../workers-requests-management/workers-list/UsersList'
const workersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Worker Management',
    path: '/apps/worker-management/workers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const WorkersPage = () => {
  const [key, setKey] = useState('workers_requests');
  return (
    <Routes>
    
      <Route element={<Outlet />}>
        <Route
          path='workers'
          element={
            <>
              <PageTitle breadcrumbs={workersBreadcrumbs}>Workers</PageTitle>
              <Tabs
									id="controlled-tab-example"
									activeKey={key}
									onSelect={(k:any) => setKey(k)}
									className="mb-3"
									><Tab eventKey="workers_requests" title="Workers Requests" >
                  {key ==="workers_requests" && <WorkersRequestListWrapper/> }
                </Tab>
									<Tab eventKey="workers_database" title="Workers Database">
										{key === "workers_database" && <UsersListWrapper />}
									</Tab>
									
									
								</Tabs>
              
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/worker-management/*' />} />
    </Routes>
  )
}

export default WorkersPage
