
import { KTSVG } from '../../../../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import * as XLSX from 'xlsx'
import { useQueryResponseData } from '../../core/QueryResponseProvider'
import { useParams } from 'react-router'

const UsersListToolbar = () => {
  const routeParams = useParams()
  console.log("🚀 ~ file: UserListToolbar.tsx:10 ~ UsersListToolbar ~ routeParams:", routeParams.id)
  const id:number = Number(routeParams.id)
  const {setItemIdForUpdate} = useListView()
  const openAddClientModal = () => {
    setItemIdForUpdate(null)
  }
  const openUpdateClientModal = ()=>{
    setItemIdForUpdate(null)
  }
  const users = useQueryResponseData()

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.json_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, ws, "Candidates");
    XLSX.writeFile(wb, "MyClients.xlsx")
  }
  return (
    <div className='d-flex justify-content-center' data-kt-user-table-toolbar='base'>
     

      {/* begin::Export */}
      {/* <button 
              type='button' 
              className='btn btn-light-primary me-3'
              onClick={handleOnExport}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary me-3' onClick={openAddClientModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Client
      </button>
      <button 
          type='button' 
          className='btn btn-primary me-3' 
          onClick={openUpdateClientModal}>
          Edit Client
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
