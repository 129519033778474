import React, { useEffect, useState } from 'react'
import {  Dropdown, Tab, Tabs } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import AppliedWorkersListWrapper from '../../../../applied-workers/workers-list/AppliedWorkersList'
import OfferedWorkersListWrapper from '../../../../offered-workers/workers-list/OfferedWorkersList'

import SelectedWorkersListWrapper from '../../../../selected-workers/workers-list/SelectedWorkersList'
import { getClient, getProjectById } from '../../core/_requests'
// import { UsersListWrapper } from './worker-management/workers-list/UsersList'
// const UsersListWrapper = React.lazy<React.ComponentType<any>>(()=> import('./worker-management/workers-list/UsersList'))
const ClientProjectDetails = () => {
	const [client, setClient] = useState<any>([]);
	const [project, setProject] = useState<any>([]);
	const routeParams = useParams()
	
	const Client_id = routeParams.client_id
	const Project_id = routeParams.projectId
	
	
	
	useEffect(() => {
 const getData = async () => {
  const clientResponse = await getClient(Client_id!);
  setClient(clientResponse?.success!);
  const projectResponse = await getProjectById(Project_id!);
  setProject(projectResponse?.success!);
 
 };
 getData();
}, [Client_id, Project_id]);
	console.log("🚀 ~ file: ClientProjectDetails.tsx:14 ~ ClientProjectDetails ~ project:", project)

	console.log("🚀 ~ file: ClientProjectDetails.tsx:9 ~ ClientProjectDetails ~ client:", client)
	const [key, setKey] = useState('applied_workers');
	const datePosted = new Date(project?.created_at)
	const reqDate =  new Date(project?.required_at)
	///////////CLIENT::DETAILS/////////////
    return (
        <>
            {/*begin::Content*/}
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						{/*begin::Container*/}
						<div className="container-xxl" id="kt_content_container">
							{/*begin::Navbar*/}
							<div className="card mb-6 mb-xl-9">
								<div className="card-body pt-9 pb-0">
									{/*begin::Details*/}
									<div className="d-flex flex-wrap flex-sm-nowrap mb-6">
										{/*begin::Image*/}
										<div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
											<img className="mw-50px mw-lg-75px" src="assets/media/svg/brand-logos/volicity-9.svg" alt="image1" />
										</div>
										{/*end::Image*/}
										{/*begin::Wrapper*/}
										<div className="flex-grow-1">
											{/*begin::Head*/}
											<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
												{/*begin::Details*/}
												<div className="d-flex flex-column">
													{/*begin::Status*/}
													<div className="d-flex align-items-center mb-1">
														<p className="text-gray-800  fs-2 fw-bold me-3">{client?.designation?client?.designation:"{Designation}"} at {client?.company_name?client?.company_name:"{Company Name}"} </p>
														<span className="badge badge-light-success me-auto"></span>
													</div>
													{/*end::Status*/}
													{/*begin::Description*/}
													<div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">Job Assigned to : Agency 1 / Branch B</div>
													{/*end::Description*/}
												</div>
												{/*end::Details*/}
												{/*begin::Actions*/}
												
												<div className="d-flex mb-4">
												<div><Dropdown className='max-w-100px me-4'>
												<Dropdown.Toggle variant='' id="dropdown-basic"className={`badge badge-light-success`}>
													Opened
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item className={`badge justify-content-center badge-light-success`}href="#/action-1">Open</Dropdown.Item>
													<Dropdown.Item className={`badge justify-content-center badge-light-danger`}href="#/action-2">Close</Dropdown.Item>
													
												</Dropdown.Menu>
												</Dropdown></div>
													<Link to={`/apps/worker-management/${Project_id}/workers`} className="btn btn-primary btn-sm">
														<>+ Add Workers</>
													</Link>
													
													
													
													
													
												</div>
												{/*end::Actions*/}
											</div>
											{/*end::Head*/}
											{/*begin::Info*/}
											<div className="d-flex flex-wrap justify-content-start">
												{/*begin::Stats*/}
												<div className="d-flex flex-wrap">
													{/*begin::Stat*/}
													<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
														{/*begin::Number*/}
														<div className="d-flex align-items-center">
															
															<div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value="75">{project?.required}</div>
														</div>
														{/*end::Number*/}
														{/*begin::Label*/}
														<div className="fw-semibold fs-6 text-gray-400">Workers Required</div>
														{/*end::Label*/}
													</div>
													{/*end::Stat*/}
													{/*begin::Stat*/}
													<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
														{/*begin::Number*/}
														<div className="d-flex align-items-center">
															
															<div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value="75">
																{project?.selected?project?.selected:"0"}
															</div>
														</div>
														{/*end::Number*/}
														{/*begin::Label*/}
														<div className="fw-semibold fs-6 text-gray-400">Workers Selected</div>
														{/*end::Label*/}
													</div>
													{/*end::Stat*/}
													{/*begin::Stat*/}
													<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
														{/*begin::Number*/}
														<div className="d-flex align-items-center">
															
															<div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value="75">{project?.required-project?.selected}</div>
														</div>
														{/*end::Number*/}
														{/*begin::Label*/}
														<div className="fw-semibold fs-6 text-gray-400">Workers Pending</div>
														{/*end::Label*/}
													</div>
													{/*end::Stat*/}
													<div className='min-w-175px'></div>
													{/*begin::Stat*/}
													<div className="border border-gray-300  border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
														{/*begin::Number*/}
														<div className="d-flex align-items-center">
															<div className="fs-4 fw-bold">{reqDate?.toLocaleDateString()}</div>
														</div>
														{/*end::Number*/}
														{/*begin::Label*/}
														<div className="fw-semibold fs-6 text-gray-400">Deadline</div>
														{/*end::Label*/}
													</div>
													{/*end::Stat*/}
													{/*begin::Stat*/}
													<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
														{/*begin::Number*/}
														<div className="d-flex align-items-center">
															
															<div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value="75">
																{datePosted
																	&&
																datePosted?.toLocaleDateString()}
															</div>
														</div>
														{/*end::Number*/}
														{/*begin::Label*/}
														<div className="fw-semibold fs-6 text-gray-400">Date Posted</div>
														{/*end::Label*/}
													</div>
													{/*end::Stat*/}
												</div>
												{/*end::Stats*/}
												
											</div>
											{/*end::Info*/}
										</div>
										{/*end::Wrapper*/}
									</div>
									{/*end::Details*/}
									<div className="separator"></div>
									{/*begin::tabs*/}
									{/* <Tabs
										defaultActiveKey="workers_required"
										activeKey={key}
										onSelect={(k:any) => setKey(k)}
										className="mb-3"
										>
										<Tab eventKey="workers_required" title="Workers Required">
											1
										</Tab>
										<Tab eventKey="workers_selected" title="Workers Selected">
											2
										</Tab>
										<Tab eventKey="" title="Workers Pending" disabled>
											3
										</Tab>
									</Tabs> */}
									{/*end::tabs*/}
								</div>
							</div>
							{/*end::Navbar*/}
								<Tabs
									id="controlled-tab-example"
									activeKey={key}
									onSelect={(k:any) => setKey(k)}
									className="mb-3"
									>
									<Tab eventKey="applied_workers" title="Direct Apply  ">
										{key === "applied_workers"&&<AppliedWorkersListWrapper/>}
									</Tab>
									<Tab eventKey="offered_workers" title="Job Offered " >
										{key ==="offered_workers"&&<OfferedWorkersListWrapper/>}
									</Tab>
									<Tab eventKey="workers_selected" title="Selected">
										{key ==="workers_selected"&&<SelectedWorkersListWrapper/>}
									</Tab>
								</Tabs>
							
							
						</div>
						{/*end::Container*/}
					</div>
					{/*end::Content*/}
        </>
)
}

export default ClientProjectDetails
