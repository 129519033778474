import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
// import {UserLastLoginCell} from './UserLastLoginCell'
// import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import { UserJobCell } from './UserJobCell'
import {UserContactCell} from './UserContactCell'
import { UserSkillsCell } from './UserSkillsCell'
import { UserStatusCell } from './UserStatusCell'
import { UserLocationCell } from './UserLocationCell'
import UserCVCell from './UserCVCell'
import UserAgencyCell from './UserAgencyCell'
import WorkerIDCell from './WorkerIDCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Worker ID' className='min-w-125px' />,
    id: 'worker_id',
    Cell: ({...props}) => <WorkerIDCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'candidate',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
  //   ),
  //   id: 'match%',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Stage' className='min-w-125px' />
  //   ),
  //   id: 'Stage',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Contact' className='min-w-125px' />
    ),
    id:"contact",
    Cell: ({...props}) => <UserContactCell user={props.data[props.row.index]} />
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Skills' className='min-w-125px' />
  //   ),
  //   id:"skills",
  //   Cell: ({...props}) => <UserSkillsCell user={props.data[props.row.index]} />
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id:"status",
    Cell: ({...props}) => <UserStatusCell user={props.data[props.row.index]} />

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'location',
    Cell: ({...props}) => <UserLocationCell user={props.data[props.row.index]} />,
  },
  
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='CV' className='min-w-125px' />
  //   ),
  //   id: 'cv',
  //   Cell: ({...props}) => <UserCVCell user={props.data[props.row.index]} />,
  // },  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-50px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />,
  }, 
]

export {usersColumns}
