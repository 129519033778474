
import {FC, useEffect, useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import swal from 'sweetalert'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID,  QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import { User } from '../../core/_models'
import {/*mailUser,*/ notifyUser} from '../../core/_requests'
type Props = {
  id: ID,
  user:User
}

const UserActionsCell: FC<Props> = ({id, user}) => {




  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  
  const [messageModalShow, setMessageModalShow] = useState(false);
  // const [mailModalShow, setMailModalShow] = useState(false);
  

  return (
    <div className="d-flex ">
    
      <Button 
          variant="" 
          className={"cvBtn "}
          onClick={() => setMessageModalShow(true)}>
            <span className="svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></span>           
      </Button>
    
      {/* <Button 
          variant="" 
          className={"cvBtn"}
          onClick={()=>setMailModalShow(true)}>
            <span className="svg-icon  svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="currentColor"/>
    </g>
</svg></span>           
      </Button> */}
      
      <MessageModal
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        user_id = {user.user_id}
      />
      {/* <MailModal
        show={mailModalShow}
        onHide={() => setMailModalShow(false)}
        user_id = {user.id}
      /> */}
    </div>
  )
}
export function MessageModal(props:any) {
  
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [message, setMessage] = useState('')
  const handleChange = (event:any) => {
    setMessage(event.target.value);
  };

  const handleSubmit =async()=>{
    await notifySelectedItems.mutateAsync()
    swal({
      title: "Message sent successfully.",
      icon: "success",
    })
}
  const notifySelectedItems = useMutation(() => notifyUser(props.user_id, message), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      
    },
    
  })
  // console.log("🚀 ~ file: UsersListGrouping.tsx:67 ~ MyVerticallyCenteredModal ~ message", message)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          BRANCH MESSAGE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group 
              className="mb-3" 
              controlId="formBasicEmail">
        <Form.Label>Enter Message</Form.Label>
        <Form.Control 
                type="email" 
                placeholder="Enter message" 
                onChange={handleChange}
                as="textarea" aria-label="With textarea" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>
      <Button 
            variant="primary" 
            // type="submit"
            // onClick={async () => await notifySelectedItems.mutateAsync()}
            onClick={handleSubmit}
            >
            
        Submit
      </Button>
    </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
// function MailModal(props:any) {
  
//   const queryClient = useQueryClient()
//   const {query} = useQueryResponse()
//   const [message, setMessage] = useState('')
//   const handleChange = (event:any) => {
//     setMessage(event.target.value);
//   };

//   const handleSubmit =async()=>{
//     await notifySelectedItems.mutateAsync()
//     swal({
//       title: "Mail sent successfully.",
//       icon: "success",
//       buttons:["Ok"],
//       dangerMode: true,
//     })
// }
//   const notifySelectedItems = useMutation(() => mailUser(props.user_id, message), {
//     // 💡 response of the mutation is passed to onSuccess
//     onSuccess: () => {
//       // ✅ update detail view directly
//       queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      
//     },
    
//   })
  
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           E-Mail
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//       <Form>
//       <Form.Group 
//               className="mb-3" 
//               controlId="formBasicEmail">
//         <Form.Label>Enter Mail</Form.Label>
//         <Form.Control 
//                 type="email" 
//                 placeholder="Enter message" 
//                 onChange={handleChange}
//                 as="textarea" aria-label="With textarea" />
//         {/* <Form.Text className="text-muted">
//           We'll never share your email with anyone else.
//         </Form.Text> */}
//       </Form.Group>
//       <Button 
//             variant="primary" 
//             // type="submit"
//             // onClick={async () => await notifySelectedItems.mutateAsync()}
//             onClick={handleSubmit}
//             >
            
//         Submit
//       </Button>
//     </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
export {UserActionsCell}
