import {Column} from 'react-table'
import { User } from '../../core/_models'
import AppliedWorkerAgencyBranchCell from './AppliedWorkerAgencyBranchCell'
import { AppliedWorkersActionsCell } from './AppliedWorkersActionsCell'
import { AppliedWorkerContactCell } from './AppliedWorkersContactCell'
import AppliedWorkerCVCell from './AppliedWorkersCVCell'
import AppliedWorkersDateAppliedCell from './AppliedWorkersDateAppliedCell'
import { AppliedWorkersInfoCell } from './AppliedWorkersInfoCell'
import { AppliedWorkersLocationCell } from './AppliedWorkersLocationCell'
import { AppliedWorkersSelectionCell } from './AppliedWorkersSelectionCell'
import { AppliedWorkersSelectionHeader } from './AppliedWorkersSelectionHeader'
import { AppliedWorkersSkillsCell } from './AppliedWorkersSkillsCell'
import { AppliedWorkersStatusCell } from './AppliedWorkersStatusCell'
import { UserCustomHeader } from './UserCustomHeader'


const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <AppliedWorkersSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <AppliedWorkersSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-100px' />,
    id: 'name',
    Cell: ({...props}) => <AppliedWorkersInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Contact' className='min-w-125px' />,
    id:"contact",
    Cell: ({...props}) => <AppliedWorkerContactCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Skills' className='min-w-125px' />
    ),
    id:"skills",
    Cell: ({...props}) => <AppliedWorkersSkillsCell user={props.data[props.row.index]} />
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <AppliedWorkersLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Agency/Branch' className='min-w-125px' />
    ),
    id: 'agency/branch',
    Cell: ({...props}) => <AppliedWorkerAgencyBranchCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Date Applied' className='min-w-125px' />
    ),
    id: 'date_applied',
    Cell: ({...props}) => <AppliedWorkersDateAppliedCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <AppliedWorkersStatusCell user={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='CV' className='min-w-75px text-center' />
    ),
    id: 'cv',
    Cell: ({...props}) => <AppliedWorkerCVCell user={props.data[props.row.index]} />,
  },  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AppliedWorkersActionsCell user={props.data[props.row.index]}id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
