import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
// import {UserLastLoginCell} from './UserLastLoginCell'
// import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

import {UserContactCell} from './UserContactCell'
import { UserSkillsCell } from './UserSkillsCell'
import { UserStatusCell } from './UserStatusCell'
import { UserLocationCell } from './UserLocationCell'
import UserCVCell from './UserCVCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'candidate',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Contact' className='min-w-125px' />
    ),
    id:"contact",
    Cell: ({...props}) => <UserContactCell user={props.data[props.row.index]} />
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Skills' className='min-w-125px' />
    ),
    id:"skills",
    Cell: ({...props}) => <UserSkillsCell user={props.data[props.row.index]} />
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id:"status",
    Cell: ({...props}) => <UserStatusCell user={props.data[props.row.index]} />

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <UserLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Agency/Branch' className='min-w-125px' />
    ),
    id: 'agencyBranch',
    Cell: ({...props}) => <UserLocationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='CV' className='min-w-125px' />
    ),
    id: 'cv',
    Cell: ({...props}) => <UserCVCell user={props.data[props.row.index]} />,
  },  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell user={props.data[props.row.index]}id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
