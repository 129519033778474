import {FC,  useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import React from 'react';
import { isNotEmpty } from '../../../../../../../../../../_metronic/helpers'

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Confirm Password'),
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  company_address: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company address is required'),
  business_category: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Business category is required'),
  phone: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
  city: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('City name is required'),
  street: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Street name is required'),
  state: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('State name is required'),
  country: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Country name is required'),
  zip_code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Zip code is required'),
  designation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  branch_id: Yup.string()
    
    .max(50, 'Maximum 50 symbols')
    .required('Zip code is required'),
  // company_logo: Yup
  //   .mixed()
  //   .required("You need to provide a file")
  //   .nullable()
  //   .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc", (value) => {
  //       return value && (
  //           value[0].type === "image/jpeg" ||
  //           value[0].type === "image/bmp" ||
  //           value[0].type === "image/png" ||
  //           value[0].type === 'application/pdf' ||
  //           value[0].type === "application/msword"
  //       );
  //   }),
    contact_person: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name of contact person is required'),


})

const ClientEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [selectedImage, setSelectedImage] = useState(null);
  const [userForEdit] = useState<User>({
    ...user,
    // avatar: user.avatar || initialUser.avatar,
    type:  initialUser.type,
    name: user?.name || initialUser.name,
    email: user?.email || initialUser.email,
    password: user?.password || initialUser.password,
    company_address: user?.company_address || initialUser.company_address,
    business_category: user?.business_category || initialUser.business_category,
    designation: user?.designation || initialUser.designation,
    branch_id: user?.branch_id || initialUser.branch_id,
    city: user?.city || initialUser.city,
    state: user?.state || initialUser.state,
    zip_code: user?.zip_code || initialUser.zip_code,
    country: user?.country || initialUser.country,
    phone: user?.phone || initialUser.phone,
    company_name: user?.company_name || initialUser.company_name,
    password_confirmation: user?.password_confirmation || initialUser.password_confirmation,
    street: user?.street || initialUser.street,
    company_logo: user?.company_logo || initialUser.company_logo,
    contact_person: user?.contact_person || initialUser.contact_person,





    
  })
  
    // const [from, setFrom] = React.useState('');
    // const [to, setTo] = React.useState('');
    // const [difference, setDifference] = React.useState<any>();
  
    // useEffect(() => {
    //   setFrom("Thu 23Jan23 11:30")
    // setTo("Thu 23Jan23 11:30")
    
    
    // }, [])
    
  
  
    // const calculateDifference = () => {
    //   const fromDate = new Date(from).valueOf();
    //   const toDate = new Date(to).valueOf();
    //   const difference = toDate - fromDate;
  
    //   setDifference(difference);
    // };
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log(values)
      
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values)
        } else {
          await createUser(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        console.log(values)

        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          {/* <div className='fv-row mb-7'>
            begin::Label
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            end::Label

            begin::Image input
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${blankImg}')`}}
            >
              begin::Preview existing avatar
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{backgroundImage: `url('${userAvatarImg}')`}}
              ></div>
              end::Preview existing avatar

              begin::Label
              <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
              end::Label

              begin::Cancel
              <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span>
              end::Cancel

              begin::Remove
              <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span>
              end::Remove
            </div>
            end::Image input

            begin::Hint
            <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
            end::Hint
          </div> */}
          {/* end::Input group */}

          
          {/* begin::Input group */}
          <div className='row mb-7'>
            <div className="col-md-6">
              {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Owner Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Full name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
            </div>
            <div className="col-md-6">
             {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Company Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Company name'
              {...formik.getFieldProps('company_name')}
              type='text'
              name='company_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.company_name && formik.errors.company_name},
                {
                  'is-valid': formik.touched.company_name && !formik.errors.company_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.company_name && formik.errors.company_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.company_name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
           </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Company Logo</label>
            {/* end::Label */}
            {/* Preview::Start */}
            {selectedImage && (
              <div>
                <img
                  alt="not found"
                  width={"150px"}
                  src={URL.createObjectURL(selectedImage)}
                />
                <br />
                <button onClick={() => setSelectedImage(null)}>Remove</button>
              </div>
            )}
            {/* Preview::End */}
            
            {/* begin::Input */}
            <input
              
              accept="image/png, application/pdf, image/jpeg, image/jpg"
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.company_logo && formik.errors.company_logo},
                {
                  'is-valid': formik.touched.company_logo && !formik.errors.company_logo,
                }
              )}
              {...formik.getFieldProps('company_logo')}
              type='file'
              onChange={(event:any) => {
                formik.setFieldValue("company_logo", event.currentTarget.files[0]);
                console.log(event.target.files[0]);
                setSelectedImage(event.target.files[0]);
              }}
              value={undefined}
              name='company_logo'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.company_logo && formik.errors.company_logo && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.company_logo}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Password</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Password'
              {...formik.getFieldProps('password')}
              type='password'
              name='password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
         {/* begin::Input group */}
        <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Confirm Password</label>
            {/* end::Label */}
            
            {/* begin::Input */}
            <input
              placeholder='Confirm Password'
              {...formik.getFieldProps('password_confirmation')}
              type='password'
              name='password_confirmation'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation},
                {
                  'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password_confirmation}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
        </div>
        {/* end::Input group */}
          {/* begin::Input group */}
            <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className=' fw-bold fs-6 mb-2'>Designation</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Designation'

                  {...formik.getFieldProps('designation')}
                  type='designation'
                  name='designation'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.designation && formik.errors.designation},
                    {
                      'is-valid': formik.touched.designation && !formik.errors.designation,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.designation && formik.errors.designation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.designation}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
                {/* begin::Label */}
                <label className=' fw-bold fs-6 mb-2'>Contact Person</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Name of Contact Person'

                  {...formik.getFieldProps('contact_person')}
                  type='text'
                  name='contact_person'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.contact_person && formik.errors.contact_person},
                    {
                      'is-valid': formik.touched.contact_person && !formik.errors.contact_person,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.contact_person && formik.errors.contact_person && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.contact_person}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
            </div>
          {/* end::Input group */}
       
          {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Company Address</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Company address'
            {...formik.getFieldProps('company_address')}
            type='text'
            name='company_address'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.company_address && formik.errors.company_address},
              {
                'is-valid': formik.touched.company_address && !formik.errors.company_address,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.company_address && formik.errors.company_address && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.company_address}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
          {/* end::Input group */}
          {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Business Category</label>
          {/* end::Label */}

          {/* begin::Input */}
          {/* <input
            placeholder='Business Category'
            {...formik.getFieldProps('business_category')}
            type='text'
            name='business_category'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.business_category && formik.errors.business_category},
              {
                'is-valid': formik.touched.business_category && !formik.errors.business_category,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          /> */}
          <select
                    {...formik.getFieldProps('business_category')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.business_category && formik.errors.business_category},
                      {
                        'is-valid': formik.touched.business_category && !formik.errors.business_category,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                    name='business_category'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='two-step'
                    data-hide-search='true'
                    // onChange={(e) => setLastLogin(e.target.value)}
                    // value={lastLogin}
                  >
                    <option value=''>Select</option>
                    <option value='Accounting & Finance'>Accounting & Finance</option>
                    <option value='Call Center & Customer Service'>Call Center & Customer Service</option>
                    <option value='Creative & Marketing'>Creative & Marketing</option>
                    <option value='Engineering & Technology'>Engineering & Technology</option>
                    <option value='Hospitality'>Hospitality</option>
                    <option value='Human Resources'>Human Resources</option>
                    <option value='Industrial & Manufacturing'>Industrial & Manufacturing</option>
                    <option value='Medical & Science'>Medical & Science</option>
                    <option value='Office, Clerical, & Administrative'>Office, Clerical, & Administrative</option>
                    <option value='Retail'>Retail</option>
                    <option value='Transportation'>Transportation</option>
                    <option value='Warehousing'>Warehousing</option>
                  </select>
          {formik.touched.business_category && formik.errors.business_category && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.business_category}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
          {/* end::Input group */}
           {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Branch Assigned</label>
          {/* end::Label */}

          {/* begin::Input */}
          <select   
                    {...formik.getFieldProps('branch_id')}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.branch_id && formik.errors.branch_id},
                      {
                        'is-valid': formik.touched.branch_id && !formik.errors.branch_id,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                    name='branch_id'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='two-step'
                    data-hide-search='true'
                  >
                    <option value=''>Select</option>
                    <option value='1'>Branch 1</option>
                    <option value='2'>Branch 2</option>
                    <option value='3'>Branch 3</option>
                    <option value='4'>Branch 4</option>
                    <option value='5'>Branch 5</option>
            </select>
          {formik.touched.branch_id && formik.errors.branch_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.branch_id}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='row mb-7'>
            <div className="col-md-6">
              {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Phone</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Phone'
              {...formik.getFieldProps('phone')}
              type='number'
              name='phone'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
            {/* end::Input */}  
            </div>
            <div className="col-md-6">
               {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Street</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Street'
              {...formik.getFieldProps('street')}
              type='text'
              name='street'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.street && formik.errors.street},
                {
                  'is-valid': formik.touched.street && !formik.errors.street,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.street && formik.errors.street && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.street}</span>
                </div>
              </div>
            )}
            </div>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='row mb-7'>
            <div className="col-md-6">
              {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>City</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='City'
              {...formik.getFieldProps('city')}
              type='text'
              name='city'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.city && formik.errors.city},
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.city}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
            </div>
            <div className="col-md-6">
               {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>State</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='State'
              {...formik.getFieldProps('state')}
              type='text'
              name='state'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.state && formik.errors.state},
                {
                  'is-valid': formik.touched.state && !formik.errors.state,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.state && formik.errors.state && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.state}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='row mb-7'>
            <div className="col-md-6">
              {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Country</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Country'
              {...formik.getFieldProps('country')}
              type='text'
              name='country'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.country && formik.errors.country},
                {
                  'is-valid': formik.touched.country && !formik.errors.country,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.country}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
            </div>
            <div className="col-md-6">
              {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Zip Code</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Zip Code'
              {...formik.getFieldProps('zip_code')}
              type='text'
              name='zip_code'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.zip_code && formik.errors.zip_code},
                {
                  'is-valid': formik.touched.zip_code && !formik.errors.zip_code,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.zip_code && formik.errors.zip_code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.zip_code}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      <div>
      
      {/* <button onClick={calculateDifference}>Calculate</button>
      {difference} */}
    </div>
      
    </>
  )
}

export {ClientEditModalForm}



